.c-nav-primary {
  display: none;
}

.c-nav-secondary {
  display: none;
}

.c-nav-footer {
  > ul {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    > li {
      > a:not([class]) {
        &:hover {
          color: $colorSand;
        }
      }

      &.is-active {
        > a:not([class]) {
          border-bottom: 1px solid $colorSand;
        }
      }
    }
  }
}

.c-nav-social {
  > ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.6rem;

    > li {
      > a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.4rem;
        height: 2.4rem;

        svg {
          display: block;
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}

.c-nav-mobile {
  display: none;
  position: relative;
  grid-column: span 2;

  &.is-active {
    display: block;
  }

  &__button {
    position: relative;
    width: 4.4rem;
    height: 4.4rem;
    background-color: transparent;
    border: 0;
    outline: 0;

    &:hover {
      cursor: pointer;
    }

    &.is-active {
      > span {
        display: none;
      }

      &:before {
        content: "";
        transform: translate(-50%, calc(50% + 0.1rem)) rotate(45deg);
      }

      &:after {
        content: "";
        transform: translate(-50%, calc(50% - 0.1rem)) rotate(-45deg);
      }
    }

    > span {
      display: block;
      width: 2.8rem;
      height: 0.2rem;
      background-color: $colorWhite;
      text-indent: -999rem;
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% + 0.1rem));
    }

    &:before {
      content: "";
      display: block;
      width: 2.8rem;
      height: 0.2rem;
      background-color: $colorWhite;
      position: absolute;
      bottom: 50%;
      left: 50%;
      transform: translate(-50%, 1.1rem);
    }

    &:after {
      content: "";
      display: block;
      width: 2.8rem;
      height: 0.2rem;
      background-color: $colorWhite;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -0.9rem);
    }
  }

  > ul {
    margin: 0;
    padding: 0;
  }

  > ul:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    > li {
      > a:not([class]) {
        font-family: $fontFamilyTitle;
        font-size: 1.6rem;
        text-transform: uppercase;

        &:hover {
          color: $colorSand;
        }
      }

      &.is-active {
        > a:not([class]) {
          border-bottom: 1px solid $colorSand;
        }
      }
    }
  }

  > ul:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin-top: 1.6rem;

    > li {
      > a:not([class]) {
        font-family: $fontFamilyTitle;
        font-size: 1.6rem;
        text-transform: uppercase;

        &:hover {
          color: $colorSand;
        }
      }

      > .c-button {
        padding: 0.6rem 1rem;
        min-height: 3.2rem;
      }
    }
  }
}

.c-nav-share {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;

  > span {
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;

    > li {
      > a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.4rem;
        height: 4.4rem;
        color: $colorWhite;
        background-color: $colorBlack;

        > svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}

@media only screen and (min-width: breakpoint("lg")) {
  .c-nav-primary {
    display: block;

    > ul {
      display: flex;
      align-items: center;
      gap: 1.6rem;

      > li {
        position: relative;

        > a:not([class]) {
          font-family: $fontFamilyTitle;
          font-size: 1.6rem;
          text-transform: uppercase;
          white-space: nowrap;

          &:hover {
            color: $colorSand;
          }
        }

        &.is-active {
          > a:not([class]) {
            border-bottom: 1px solid $colorSand;
          }
        }

        &:hover {
          > ul {
            display: block;
          }
        }

        > ul {
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          padding-top: 1rem;

          > li {
            > a:not([class]) {
              display: block;
              font-size: 1.4rem;
              white-space: nowrap;
              color: $colorBlack;
              padding: 0.8rem 1.6rem;
              background-color: $colorSand;

              &:hover {
                background-color: darken($colorSand, 8%);
              }
            }
          }
        }
      }
    }
  }

  .c-nav-secondary {
    display: block;

    > ul {
      display: flex;
      align-items: center;
      gap: 1.6rem;

      > li {
        > a:not([class]) {
          font-family: $fontFamilyTitle;
          font-size: 1.6rem;

          &:hover {
            color: $colorSand;
          }
        }

        > .c-button {
          padding: 0.6rem 1rem;
          min-height: 3.2rem;
        }
      }
    }
  }

  .c-nav-mobile {
    display: none;

    &.is-active {
      display: none;
    }

    &__button {
      display: none;
    }
  }
}
