.o-container {
    max-width: 100%;
    margin: 0 auto;

    &--default {
        width: 1280px;
    }

    &--text {
        width: 800px;
    }

    &--left {
        @media only screen and (min-width: breakpoint("lg")) {
            padding-right: 50%;
        }
    }

    &--right {
        @media only screen and (min-width: breakpoint("lg")) {
            padding-left: 50%;
        }
    }
}