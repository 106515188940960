.o-header {
  position: sticky;
  z-index: 999;
  top: 0;
  left: 0;

  &-primary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 8rem;
    padding: 1.6rem 0;
    background-color: $colorBlack;
    color: $colorWhite;

    &__layout {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 4.8rem;
      align-items: center;

      > * {
        &:nth-child(2) {
          justify-self: start;
        }

        // &:nth-child(4) {
        //     justify-self: end;
        // }
      }
    }
  }

  &-secondary {
    background-color: $colorBeige;
    color: inherit;

    &__layout {
      min-height: 3.2rem;
      display: grid;
      grid-template-columns: auto;
      gap: 1.6rem;
      align-items: center;

      > * {
        &:nth-child(2) {
          justify-self: center;
        }
      }
    }
  }
}

@media only screen and (min-width: breakpoint("lg")) {
  .o-header {
    &-primary {
      min-height: 9.6rem;
      background-color: $colorBlack;
      color: $colorWhite;

      &__layout {
        > * {
          &:nth-child(1) {
            justify-self: start;
          }

          &:nth-child(3) {
            justify-self: end;
          }
        }
      }
    }

    &-secondary {
      &__layout {
        grid-template-columns: 1fr auto;

        > * {
          &:nth-child(1),
          &:nth-child(2) {
            justify-self: end;
          }
        }
      }
    }
  }
}
