.c-section {
  &.has-background-color {
    background-color: $colorBeige;
  }
}

.c-carousel {
  width: 100%;
}

.c-carousel--quotes {
  background-color: $colorWhite;
}

.c-carousel__wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 16px;

  > * {
    min-width: 0px;
    min-height: 0px;
  }
}

.c-carousel__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  padding: 0;
  margin: 0;

  &:hover {
    cursor: pointer;
  }

  > svg {
    flex: 0 0 auto;
    display: block;
    width: 36px;
    height: 36px;
  }
}

.c-hero {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-template-areas: "hero";

  > * {
    min-width: 0px;
    min-height: 0px;
  }

  &__image {
    grid-area: hero;
    z-index: 1;
    width: 100%;
    height: 100%;

    aspect-ratio: 2/1;
    object-fit: cover;

    @media (orientation: landscape) {
      max-height: 75vh;
    }
  }

  &__body {
    grid-area: hero;
    z-index: 2;
    align-self: center;

    max-width: 66%;

    @media only screen and (min-width: breakpoint("lg")) {
      max-width: 100%;
    }

    h1 {
      font-size: 2.8rem;
      margin-bottom: calc(1.3em / 2.4);

      @media only screen and (min-width: breakpoint("lg")) {
        font-size: 4rem;
        margin-bottom: calc(1.3em / 4);
      }
    }

    p,
    ol,
    ul {
      line-height: 1.6em;
    }
  }
}

.c-quote {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 40px;

  &--carousel {
    padding: 7.2rem;
    align-items: center;
    grid-template-columns: 1fr;

    @media only screen and (min-width: breakpoint("md")) {
      grid-template-columns: auto 1fr;
    }
  }

  &__image {
    &--carousel {
      width: 120px;
      height: 120px;
      margin: 0 auto;
    }
  }

  &__icon {
    width: 48px;
    height: 48px;
    fill: currentColor;
  }

  &__body {
    font-size: 1.9rem;
    line-height: 1.6em;
  }

  &__owner {
    font-family: $fontFamilyTitle;
    font-size: 1.7rem;
    color: $colorOak;
  }
}

.c-video {
  > video {
    width: 100%;
  }
}

.c-teaser {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: calc(1.3em / 1.5);
  min-height: 100%;
}

.c-anchor {
  scroll-margin-top: 11.2rem;
}

@media only screen and (min-width: breakpoint("lg")) {
  .c-anchor {
    scroll-margin-top: 12.8rem;
  }
}
