:root {
    --cc-bg: #{$colorWhite};
    --cc-primary-color: #{$colorBlack};
    --cc-secondary-color: #{$colorBlack};
    --cc-btn-primary-bg: #{$colorBlack};
    --cc-btn-primary-color: #{$colorWhite};
    --cc-btn-primary-border-color: var(--cc-btn-primary-bg);
    --cc-btn-primary-hover-bg: #{darken($colorBlack, 8%)};
    --cc-btn-primary-hover-color: #{$colorWhite};
    --cc-btn-primary-hover-border-color: var(--cc-btn-primary-hover-bg);
    --cc-btn-secondary-bg: #{$colorSand};
    --cc-btn-secondary-color: #{$colorBlack};
    --cc-btn-secondary-border-color: var(--cc-btn-secondary-bg);
    --cc-btn-secondary-hover-bg: #{darken($colorSand, 8%)};
    --cc-btn-secondary-hover-color: #{$colorBlack};
    --cc-btn-secondary-hover-border-color: var(--cc-btn-secondary-hover-bg);
    --cc-separator-border-color: #{$colorBeige};
    --cc-toggle-on-bg: #21a97b;
    --cc-toggle-off-bg: #a2afbb;
    --cc-toggle-on-knob-bg: #{$colorWhite};
    --cc-toggle-off-knob-bg: #{$colorWhite};
    --cc-toggle-enabled-icon-color: var(--cc-bg);
    --cc-toggle-disabled-icon-color: var(--cc-bg);
    --cc-toggle-readonly-bg: #d0dfe6;
    --cc-toggle-readonly-knob-bg: #{$colorWhite};
    --cc-toggle-readonly-knob-icon-color: #a5b2c0;
    --cc-section-category-border: var(--cc-cookie-category-block-bg);
    --cc-cookie-category-block-bg: #f0f4f7;
    --cc-cookie-category-block-border: #f0f4f7;
    --cc-cookie-category-block-hover-bg: #e9eff4;
    --cc-cookie-category-block-hover-border: #e9eff4;
    --cc-cookie-category-expanded-block-bg: transparent;
    --cc-cookie-category-expanded-block-hover-bg: #dee4e9;
    --cc-overlay-bg: rgba(0, 0, 0, .65);
    --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
    --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);
    --cc-footer-bg: var(--cc-btn-secondary-bg);
    --cc-footer-color: var(--cc-secondary-color);
    --cc-footer-border-color: #e4eaed;
}

#cc-main {
    background: transparent;
    color: var(--cc-primary-color);
    font-family: var(--cc-font-family);
    font-size: 1.5rem;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.6em;
    position: relative;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    position: fixed;
    z-index: var(--cc-z-index);
}

#cc-main a,
#cc-main button,
#cc-main input {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    font-family: inherit;
    font-size: 100%;
    line-height: normal;
    margin: 0;
    overflow: hidden;
}

:root {
    --cc-font-family-title: #{$fontFamilyTitle};
    --cc-font-family: #{$fontFamilyDefault};
    --cc-modal-border-radius: 0;
    --cc-btn-border-radius: 0;
    --cc-modal-transition-duration: .25s;
    --cc-link-color: var(--cc-btn-primary-bg);
    --cc-modal-margin: 1rem;
    --cc-z-index: 2147483647;
}

#cc-main a,
#cc-main b,
#cc-main em,
#cc-main strong {
    font-weight: 700;
}

#cc-main button>span {
    pointer-events: none;
}

html.disable--interaction.show--consent,
html.disable--interaction.show--consent body {
    height: auto !important;
    overflow: hidden !important
}

@media (prefers-reduced-motion) {
    #cc-main {
        --cc-modal-transition-duration: 0s
    }
}

#cc-main .cm {
    background: var(--cc-bg);
    border-radius: var(--cc-modal-border-radius);
    box-shadow: 0 .625em 1.875em rgba(0, 0, 2, .3);
    display: flex;
    flex-direction: column;
    width: 36rem;
    max-width: calc(100% - (2 * var(--cc-modal-margin)));
    opacity: 0;
    overflow: hidden;
    position: fixed;
    transform: translateY(1.6em);
    visibility: hidden;
    z-index: 1
}

#cc-main .cm--bottom {
    bottom: var(--cc-modal-margin)
}

#cc-main .cm--right {
    right: var(--cc-modal-margin)
}

#cc-main .cm__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    gap: 1.6rem;
    padding: 2.4rem;
}

#cc-main .cm__texts {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
}

#cc-main .cm__title {
    font-family: var(--cc-font-family-title);
    font-size: 2rem;
    line-height: 1.3em;
    margin-bottom: calc(1.3em / 2);
}

#cc-main .cm__desc {
    color: var(--cc-secondary-color);
    max-height: 50vh;
    overflow-y: auto;
}

#cc-main .cm__btns {
    border-top: 1px solid var(--cc-separator-border-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .4rem;
}

#cc-main .cm__btn-group {
    display: flex;
    flex-direction: column;
    gap: .4rem;
}

#cc-main .cm__btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding: 1rem 1.4rem;
    min-height: 4rem;
    background: var(--cc-btn-primary-bg);
    border: 1px solid var(--cc-btn-primary-border-color);
    outline: 0;
    border-radius: var(--cc-btn-border-radius);
    font-family: var(--cc-font-family-title);
    font-size: 1.6rem;
    text-align: center;
    line-height: 1em;
    white-space: nowrap;
    color: var(--cc-btn-primary-color);
}

#cc-main .cm__btn:hover {
    background: var(--cc-btn-primary-hover-bg);
    border-color: var(--cc-btn-primary-hover-border-color);
    color: var(--cc-btn-primary-hover-color);
}

#cc-main .cm__btn--secondary {
    background: var(--cc-btn-secondary-bg);
    border-color: var(--cc-btn-secondary-border-color);
    color: var(--cc-btn-secondary-color);
}

#cc-main .cm__btn--secondary:hover {
    background: var(--cc-btn-secondary-hover-bg);
    border-color: var(--cc-btn-secondary-hover-border-color);
    color: var(--cc-btn-secondary-hover-color);
}

#cc-main .cm__footer {
    background: var(--cc-footer-bg);
    border-top: 1px solid var(--cc-footer-border-color);
    color: var(--cc-footer-color);
}

#cc-main .cc--anim .cm,
#cc-main .cc--anim.cm-wrapper:before {
    transition: opacity var(--cc-modal-transition-duration) ease, visibility var(--cc-modal-transition-duration) ease, transform var(--cc-modal-transition-duration) ease;
}

.disable--interaction #cc-main .cm-wrapper:before {
    background: var(--cc-overlay-bg);
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: 0;
}

.show--consent #cc-main .cc--anim .cm {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.show--consent #cc-main .cc--anim .cm--middle {
    transform: translateY(-50%);
}

.show--consent #cc-main .cc--anim .cm--bar {
    transform: translateY(0);
}

.show--consent #cc-main .cc--anim.cm-wrapper:before {
    opacity: 1;
    visibility: visible;
}

:root {
    --cc-pm-toggle-border-radius: 11.5px;
}

#cc-main .pm-wrapper {
    position: relative;
    z-index: 2;
}

#cc-main .pm {
    background: var(--cc-bg);
    border-radius: var(--cc-modal-border-radius);
    box-shadow: 0 .625em 1.875em rgba(0, 0, 2, .3);
    display: flex;
    flex-direction: column;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    visibility: hidden;
    width: 100%;
    z-index: 1;
}

#cc-main svg {
    fill: none;
    width: 100%;
}

#cc-main .pm__header {
    align-items: center;
    border-bottom: 1px solid var(--cc-separator-border-color);
    display: flex;
    justify-content: space-between;
    padding: .8rem .8rem .8rem 2.4rem;
}

#cc-main .pm__title {
    font-family: var(--cc-font-family-title);
    font-size: 2rem;
    line-height: 1em;
    align-items: center;
    display: flex;
    flex: 1;
    margin-bottom: 0;
    margin-right: 1.6rem;
}

#cc-main .pm__close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    position: relative;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
}

#cc-main .pm__close-btn span {
    display: flex;
    height: 50%;
    width: 50%
}

#cc-main .pm__close-btn svg {
    stroke: var(--cc-primary-color);
}

#cc-main .pm__body {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    flex: 1;
    overflow-y: auto;
    overflow-y: overlay;
    padding: 2.4rem;
}

#cc-main .pm__section,
#cc-main .pm__section--toggle {
    border-radius: var(--cc-btn-border-radius);
    display: flex;
    flex-direction: column;
}

#cc-main .pm__section--toggle .pm__section-desc-wrapper {
    border: 1px solid var(--cc-cookie-category-block-border);
    border-radius: var(--cc-btn-border-radius);
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: none;
    margin-top: 0;
    overflow: hidden
}

#cc-main .pm__section {
    border: 1px solid var(--cc-separator-border-color);
}

#cc-main .pm__section:first-child {
    border: none;
}

#cc-main .pm__section-toggles {
    display: flex;
    flex-direction: column;
    gap: .8rem;
}

#cc-main .pm__section-toggles .pm__section-desc {
    padding: 1.6rem;
}

#cc-main .pm__section--toggle {
    background: var(--cc-cookie-category-block-bg);
}

#cc-main .pm__section--toggle .pm__section-title {
    background: var(--cc-cookie-category-block-bg);
    border: 1px solid var(--cc-cookie-category-block-border);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 5.6rem;
    position: relative;
    width: 100%
}

#cc-main .pm__section--toggle.is-expanded {
    --cc-cookie-category-block-bg: transparent;
    --cc-cookie-category-block-border: var(--cc-cookie-category-expanded-block-hover-bg)
}

#cc-main .pm__section--toggle.is-expanded .pm__section-title {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

#cc-main .pm__section--toggle.is-expanded .pm__section-arrow svg {
    transform: scale(.5) rotate(180deg)
}

#cc-main .pm__section--toggle.is-expanded .pm__section-desc-wrapper {
    display: flex
}

#cc-main .pm__section--expandable .pm__section-title {
    cursor: pointer;
    padding-left: 5.6rem;
}

#cc-main .pm__section--expandable .pm__section-arrow {
    background: var(--cc-toggle-readonly-bg);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    width: 2rem;
    height: 2rem;    
    pointer-events: none;
    position: absolute;
    left: 2rem;
}

#cc-main .pm__section--expandable .pm__section-arrow svg {
    stroke: var(--cc-primary-color);
    transform: scale(.5)
}

#cc-main .pm__section-title-wrapper {
    align-items: center;
    display: flex;
    position: relative
}

#cc-main .section__toggle,
#cc-main .section__toggle-wrapper,
#cc-main .toggle__icon,
#cc-main .toggle__label {
    border-radius: var(--cc-pm-toggle-border-radius);
    height: 23px;
    transform: translateZ(0);
    width: 50px
}

#cc-main .section__toggle-wrapper {
    cursor: pointer;
    position: absolute;
    right: 18px;
    z-index: 1
}

#cc-main .toggle-service {
    height: 19px;
    position: relative;
    right: 0;
    width: 42px
}

#cc-main .toggle-service .section__toggle,
#cc-main .toggle-service .toggle__icon,
#cc-main .toggle-service .toggle__label {
    height: 19px;
    width: 42px
}

#cc-main .toggle-service .toggle__icon {
    position: relative
}

#cc-main .toggle-service .toggle__icon-circle {
    height: 19px;
    width: 19px
}

#cc-main .toggle-service .section__toggle:checked~.toggle__icon .toggle__icon-circle {
    transform: translateX(23px)
}

#cc-main .pm__section--toggle:nth-child(2) .section__toggle-wrapper:after {
    display: none !important
}

#cc-main .section__toggle {
    border: 0;
    cursor: pointer;
    display: block;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0
}

#cc-main .section__toggle:disabled {
    cursor: not-allowed
}

#cc-main .toggle__icon {
    background: var(--cc-toggle-off-bg);
    box-shadow: 0 0 0 1px var(--cc-toggle-off-bg);
    display: flex;
    flex-direction: row;
    pointer-events: none;
    position: absolute;
    transition: all .25s ease
}

#cc-main .toggle__icon-circle {
    background: var(--cc-toggle-off-knob-bg);
    border: none;
    border-radius: var(--cc-pm-toggle-border-radius);
    box-shadow: 0 1px 2px rgba(24, 32, 3, .36);
    display: block;
    height: 23px;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform .25s ease, background-color .25s ease;
    width: 23px
}

#cc-main .toggle__icon-off,
#cc-main .toggle__icon-on {
    height: 100%;
    position: absolute;
    transition: opacity .15s ease;
    width: 100%
}

#cc-main .toggle__icon-on {
    opacity: 0;
    transform: rotate(45deg)
}

#cc-main .toggle__icon-on svg {
    stroke: var(--cc-toggle-on-bg);
    transform: scale(.55) rotate(-45deg)
}

#cc-main .toggle__icon-off {
    opacity: 1
}

#cc-main .toggle__icon-off svg {
    stroke: var(--cc-toggle-off-bg);
    transform: scale(.55)
}

#cc-main .section__toggle:checked~.toggle__icon {
    background: var(--cc-toggle-on-bg);
    box-shadow: 0 0 0 1px var(--cc-toggle-on-bg)
}

#cc-main .section__toggle:checked~.toggle__icon .toggle__icon-circle {
    background-color: var(--cc-toggle-on-knob-bg);
    transform: translateX(27px)
}

#cc-main .section__toggle:checked~.toggle__icon .toggle__icon-on {
    opacity: 1
}

#cc-main .section__toggle:checked~.toggle__icon .toggle__icon-off {
    opacity: 0
}

#cc-main .section__toggle:checked:disabled~.toggle__icon {
    background: var(--cc-toggle-readonly-bg);
    box-shadow: 0 0 0 1px var(--cc-toggle-readonly-bg)
}

#cc-main .section__toggle:checked:disabled~.toggle__icon .toggle__icon-circle {
    background: var(--cc-toggle-readonly-knob-bg);
    box-shadow: none
}

#cc-main .section__toggle:checked:disabled~.toggle__icon svg {
    stroke: var(--cc-toggle-readonly-knob-icon-color)
}

#cc-main .toggle__label {
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: -1
}

#cc-main .pm__section-desc-wrapper {
    color: var(--cc-secondary-color);
    display: flex;
    flex-direction: column;
}

#cc-main .pm__section-desc-wrapper>:not(:last-child) {
    border-bottom: 1px solid var(--cc-cookie-category-block-border)
}

#cc-main .pm__section-services {
    display: flex;
    flex-direction: column
}

#cc-main .pm__service {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    transition: background-color .15s ease
}

#cc-main .pm__service:hover {
    background-color: var(--cc-cookie-category-block-hover-bg)
}

#cc-main .pm__service-header {
    align-items: center;
    display: flex;
    margin-right: 1em;
    width: 100%
}

#cc-main .pm__service-icon {
    border: 2px solid;
    border-radius: 100%;
    height: 8px;
    margin-left: 6px;
    margin-right: 20px;
    margin-top: 1px;
    min-width: 8px
}

#cc-main .pm__service-title {
    width: 100%;
    word-break: break-word
}

#cc-main .pm__section-table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    text-align: left;
    width: 100%
}

#cc-main .pm__table-caption {
    text-align: left
}

#cc-main .pm__table-caption,
#cc-main .pm__table-head>tr {
    border-bottom: 1px dashed var(--cc-separator-border-color);
    color: var(--cc-primary-color);
    font-weight: 600
}

#cc-main .pm__table-tr {
    transition: background-color .15s ease
}

#cc-main .pm__table-tr:hover {
    background: var(--cc-cookie-category-block-hover-bg)
}

#cc-main .pm__table-caption,
#cc-main .pm__table-td,
#cc-main .pm__table-th {
    vertical-align: top
}

#cc-main .pm__footer {
    border-top: 1px solid var(--cc-separator-border-color);
    display: flex;
    gap: .4rem;
    justify-content: space-between;
    padding: .8rem 2.4rem;
}

#cc-main .pm__btn-group {
    display: flex;
    gap: .4rem;
}

#cc-main .pm__btn {
    @extend .cm__btn;
}


#cc-main .pm__btn--secondary {
    @extend .cm__btn--secondary;
}

#cc-main .pm--box {
    height: calc(100% - 2em);
    margin: 0 auto;
    max-height: 37.5em;
    max-width: 43em;
    left: var(--cc-modal-margin);
    right: var(--cc-modal-margin);
    top: 50%;
    transform: translateY(calc(-50% + 1.6em))
}

#cc-main .pm-overlay {
    background: var(--cc-overlay-bg);
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: 1
}

#cc-main .cc--anim .pm,
#cc-main .cc--anim .pm-overlay {
    transition: opacity var(--cc-modal-transition-duration) ease, visibility var(--cc-modal-transition-duration) ease, transform var(--cc-modal-transition-duration) ease
}

.show--preferences #cc-main .cc--anim .pm {
    opacity: 1;
    visibility: visible !important
}

.show--preferences #cc-main .cc--anim .pm-overlay {
    opacity: 1;
    visibility: visible
}

@media screen and (max-width:640px) {
    #cc-main .pm {
        border-radius: 0;
        bottom: 0;
        height: auto;
        left: 0;
        max-height: 100%;
        max-width: none !important;
        right: 0;
        top: 0;
        transform: translateY(1.6rem)
    }

    #cc-main .pm__btn-group,
    #cc-main .pm__footer {
        flex-direction: column !important
    }

    #cc-main .pm__btn-group {
        display: flex !important
    }

    .show--preferences #cc-main .cc--anim .pm {
        transform: translateY(0) !important
    }
}