html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.6em;
  color: #020307;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
.c-form__fieldset__title,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: inherit;
}

address {
  font-style: normal;
}

h1,
.h1,
h2,
.h2,
h3,
.c-form__fieldset__title,
.h3 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  line-height: 1.3em;
  color: inherit;
}
h1:last-child,
.h1:last-child,
h2:last-child,
.h2:last-child,
h3:last-child,
.c-form__fieldset__title:last-child,
.h3:last-child {
  margin-bottom: 0;
}

h1,
.h1 {
  font-size: 2.8rem;
  margin-bottom: 0.5416666667em;
}
@media only screen and (min-width: 1024px) {
  h1,
  .h1 {
    font-size: 3.2rem;
    margin-bottom: 0.40625em;
  }
}

h2,
.h2 {
  font-size: 2rem;
  margin-bottom: 0.65em;
}
@media only screen and (min-width: 1024px) {
  h2,
  .h2 {
    font-size: 2.8rem;
    margin-bottom: 0.5416666667em;
  }
}

h3, .c-form__fieldset__title,
.h3 {
  font-size: 1.6rem;
  margin-bottom: 0.8125em;
}
@media only screen and (min-width: 1024px) {
  h3, .c-form__fieldset__title,
  .h3 {
    font-size: 2rem;
    margin-bottom: 0.65em;
  }
}

h4,
.h4 {
  font-size: 1.4rem;
  margin-bottom: 0.9285714286em;
}
@media only screen and (min-width: 1024px) {
  h4,
  .h4 {
    font-size: 1.8rem;
    margin-bottom: 0.7222222222em;
  }
}

h5,
.h5 {
  font-size: 1.4rem;
  margin-bottom: 0.9285714286em;
}
@media only screen and (min-width: 1024px) {
  h5,
  .h5 {
    font-size: 1.6rem;
    margin-bottom: 0.8125em;
  }
}

h6,
.h6 {
  font-size: 1.4rem;
  margin-bottom: 0.9285714286em;
}
@media only screen and (min-width: 1024px) {
  h6,
  .h6 {
    font-size: 1.4rem;
    margin-bottom: 0.9285714286em;
  }
}

p,
ol,
ul {
  margin-bottom: 0.8666666667em;
}
p:last-child,
ol:last-child,
ul:last-child {
  margin-bottom: 0;
}

.text-beige {
  color: #f9f1ef;
}

.text-sand {
  color: #dab9a5;
}

.text-oak {
  color: #995a50;
}

.text-white {
  color: #fff;
}

.o-container {
  max-width: 100%;
  margin: 0 auto;
}
.o-container--default {
  width: 1280px;
}
.o-container--text {
  width: 800px;
}
@media only screen and (min-width: 1024px) {
  .o-container--left {
    padding-right: 50%;
  }
}
@media only screen and (min-width: 1024px) {
  .o-container--right {
    padding-left: 50%;
  }
}

.o-header {
  position: sticky;
  z-index: 999;
  top: 0;
  left: 0;
}
.o-header-primary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 8rem;
  padding: 1.6rem 0;
  background-color: #020307;
  color: #fff;
}
.o-header-primary__layout {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 4.8rem;
  align-items: center;
}
.o-header-primary__layout > *:nth-child(2) {
  justify-self: start;
}
.o-header-secondary {
  background-color: #f9f1ef;
  color: inherit;
}
.o-header-secondary__layout {
  min-height: 3.2rem;
  display: grid;
  grid-template-columns: auto;
  gap: 1.6rem;
  align-items: center;
}
.o-header-secondary__layout > *:nth-child(2) {
  justify-self: center;
}

@media only screen and (min-width: 1024px) {
  .o-header-primary {
    min-height: 9.6rem;
    background-color: #020307;
    color: #fff;
  }
  .o-header-primary__layout > *:nth-child(1) {
    justify-self: start;
  }
  .o-header-primary__layout > *:nth-child(3) {
    justify-self: end;
  }
  .o-header-secondary__layout {
    grid-template-columns: 1fr auto;
  }
  .o-header-secondary__layout > *:nth-child(1), .o-header-secondary__layout > *:nth-child(2) {
    justify-self: end;
  }
}
.o-blog__layout {
  display: grid;
  gap: 3.2rem;
}

@media only screen and (min-width: 768px) {
  .o-blog__layout {
    grid-template-columns: repeat(3, 1fr);
  }
}
.o-jobs__layout {
  display: grid;
  gap: 3.2rem;
}

@media only screen and (min-width: 768px) {
  .o-jobs__layout {
    grid-template-columns: repeat(2, 1fr);
  }
}
.o-footer {
  background-color: #020307;
  color: #fff;
}
.o-footer__layout {
  display: grid;
  grid-template-columns: 1fr;
  gap: 4rem;
}
.o-footer__address svg {
  display: inline-block;
  vertical-align: middle;
  width: 1.6rem;
  height: 1.6rem;
}

@media only screen and (min-width: 768px) {
  .o-footer__layout {
    grid-template-columns: repeat(3, 1fr);
  }
}
.c-brand {
  width: 10rem;
}

@media only screen and (min-width: 1024px) {
  .c-brand {
    display: block;
    width: 14rem;
  }
}
.c-nav-primary {
  display: none;
}

.c-nav-secondary {
  display: none;
}

.c-nav-footer > ul {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.c-nav-footer > ul > li > a:not([class]):hover {
  color: #dab9a5;
}
.c-nav-footer > ul > li.is-active > a:not([class]) {
  border-bottom: 1px solid #dab9a5;
}

.c-nav-social > ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.6rem;
}
.c-nav-social > ul > li > a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
}
.c-nav-social > ul > li > a svg {
  display: block;
  width: 2rem;
  height: 2rem;
}

.c-nav-mobile {
  display: none;
  position: relative;
  grid-column: span 2;
}
.c-nav-mobile.is-active {
  display: block;
}
.c-nav-mobile__button {
  position: relative;
  width: 4.4rem;
  height: 4.4rem;
  background-color: transparent;
  border: 0;
  outline: 0;
}
.c-nav-mobile__button:hover {
  cursor: pointer;
}
.c-nav-mobile__button.is-active > span {
  display: none;
}
.c-nav-mobile__button.is-active:before {
  content: "";
  transform: translate(-50%, calc(50% + 0.1rem)) rotate(45deg);
}
.c-nav-mobile__button.is-active:after {
  content: "";
  transform: translate(-50%, calc(50% - 0.1rem)) rotate(-45deg);
}
.c-nav-mobile__button > span {
  display: block;
  width: 2.8rem;
  height: 0.2rem;
  background-color: #fff;
  text-indent: -999rem;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 0.1rem));
}
.c-nav-mobile__button:before {
  content: "";
  display: block;
  width: 2.8rem;
  height: 0.2rem;
  background-color: #fff;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 1.1rem);
}
.c-nav-mobile__button:after {
  content: "";
  display: block;
  width: 2.8rem;
  height: 0.2rem;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -0.9rem);
}
.c-nav-mobile > ul {
  margin: 0;
  padding: 0;
}
.c-nav-mobile > ul:nth-child(1) {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.c-nav-mobile > ul:nth-child(1) > li > a:not([class]) {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.6rem;
  text-transform: uppercase;
}
.c-nav-mobile > ul:nth-child(1) > li > a:not([class]):hover {
  color: #dab9a5;
}
.c-nav-mobile > ul:nth-child(1) > li.is-active > a:not([class]) {
  border-bottom: 1px solid #dab9a5;
}
.c-nav-mobile > ul:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 1.6rem;
}
.c-nav-mobile > ul:nth-child(2) > li > a:not([class]) {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.6rem;
  text-transform: uppercase;
}
.c-nav-mobile > ul:nth-child(2) > li > a:not([class]):hover {
  color: #dab9a5;
}
.c-nav-mobile > ul:nth-child(2) > li > .c-button {
  padding: 0.6rem 1rem;
  min-height: 3.2rem;
}

.c-nav-share {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
}
.c-nav-share > span {
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
}
.c-nav-share > ul {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}
.c-nav-share > ul > li > a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.4rem;
  height: 4.4rem;
  color: #fff;
  background-color: #020307;
}
.c-nav-share > ul > li > a > svg {
  width: 2rem;
  height: 2rem;
}

@media only screen and (min-width: 1024px) {
  .c-nav-primary {
    display: block;
  }
  .c-nav-primary > ul {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }
  .c-nav-primary > ul > li {
    position: relative;
  }
  .c-nav-primary > ul > li > a:not([class]) {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1.6rem;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .c-nav-primary > ul > li > a:not([class]):hover {
    color: #dab9a5;
  }
  .c-nav-primary > ul > li.is-active > a:not([class]) {
    border-bottom: 1px solid #dab9a5;
  }
  .c-nav-primary > ul > li:hover > ul {
    display: block;
  }
  .c-nav-primary > ul > li > ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    padding-top: 1rem;
  }
  .c-nav-primary > ul > li > ul > li > a:not([class]) {
    display: block;
    font-size: 1.4rem;
    white-space: nowrap;
    color: #020307;
    padding: 0.8rem 1.6rem;
    background-color: #dab9a5;
  }
  .c-nav-primary > ul > li > ul > li > a:not([class]):hover {
    background-color: #cea388;
  }
  .c-nav-secondary {
    display: block;
  }
  .c-nav-secondary > ul {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }
  .c-nav-secondary > ul > li > a:not([class]) {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1.6rem;
  }
  .c-nav-secondary > ul > li > a:not([class]):hover {
    color: #dab9a5;
  }
  .c-nav-secondary > ul > li > .c-button {
    padding: 0.6rem 1rem;
    min-height: 3.2rem;
  }
  .c-nav-mobile {
    display: none;
  }
  .c-nav-mobile.is-active {
    display: none;
  }
  .c-nav-mobile__button {
    display: none;
  }
}
.c-grid__layout--blocks {
  position: relative;
  display: grid;
  grid-template-columns: repeat(var(--umb-block-grid--grid-columns, 1), minmax(0, 1fr));
  grid-auto-flow: row;
  grid-auto-rows: minmax(0, auto);
  column-gap: var(--umb-block-grid--column-gap, 0);
  row-gap: var(--umb-block-grid--row-gap, 0);
  min-height: 100%;
}
.c-section .c-grid__layout--blocks {
  row-gap: var(--umb-block-grid--areas-row-gap, 4rem);
}

.c-grid__item--blocks {
  /* For small devices we scale columnSpan by three, to make everything bigger than 1/3 take full width: */
  grid-column-end: span min(var(--umb-block-grid--item-column-span, 1) * 3, var(--umb-block-grid--grid-columns));
  grid-row: span var(--umb-block-grid--item-row-span, 1);
}

.c-grid__layout--area,
.c-grid__layout--blocks::part(area-container) {
  position: relative;
  display: grid;
  grid-template-columns: repeat(var(--umb-block-grid--area-grid-columns, var(--umb-block-grid--grid-columns, 1)), minmax(0, 1fr));
  grid-auto-flow: row;
  grid-auto-rows: minmax(0, auto);
  column-gap: var(--umb-block-grid--areas-column-gap, 0);
  row-gap: var(--umb-block-grid--areas-row-gap, 4rem);
}

.c-grid__item--area {
  position: relative;
  /* For small devices we scale columnSpan by twelve, to make everything bigger than 1/12 take full width: */
  grid-column-end: span min(var(--umb-block-grid--area-column-span, 1) * 12, var(--umb-block-grid--area-grid-columns));
  grid-row: span var(--umb-block-grid--area-row-span, 1);
}

@media only screen and (min-width: 768px) {
  .c-section .c-grid__layout--blocks {
    column-gap: var(--umb-block-grid--areas-column-gap, 4rem);
  }
  .c-grid__item--blocks {
    grid-column-end: span min(var(--umb-block-grid--item-column-span, 1), var(--umb-block-grid--grid-columns));
  }
  .c-grid__layout--area {
    column-gap: var(--umb-block-grid--areas-column-gap, 4rem);
  }
  .c-grid__item--area {
    grid-column-end: span min(var(--umb-block-grid--area-column-span, 1), var(--umb-block-grid--area-grid-columns));
  }
}
.c-section.has-background-color {
  background-color: #f9f1ef;
}

.c-carousel {
  width: 100%;
}

.c-carousel--quotes {
  background-color: #fff;
}

.c-carousel__wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 16px;
}
.c-carousel__wrapper > * {
  min-width: 0px;
  min-height: 0px;
}

.c-carousel__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  padding: 0;
  margin: 0;
}
.c-carousel__button:hover {
  cursor: pointer;
}
.c-carousel__button > svg {
  flex: 0 0 auto;
  display: block;
  width: 36px;
  height: 36px;
}

.c-hero {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-template-areas: "hero";
}
.c-hero > * {
  min-width: 0px;
  min-height: 0px;
}
.c-hero__image {
  grid-area: hero;
  z-index: 1;
  width: 100%;
  height: 100%;
  aspect-ratio: 2/1;
  object-fit: cover;
}
@media (orientation: landscape) {
  .c-hero__image {
    max-height: 75vh;
  }
}
.c-hero__body {
  grid-area: hero;
  z-index: 2;
  align-self: center;
  max-width: 66%;
}
@media only screen and (min-width: 1024px) {
  .c-hero__body {
    max-width: 100%;
  }
}
.c-hero__body h1 {
  font-size: 2.8rem;
  margin-bottom: 0.5416666667em;
}
@media only screen and (min-width: 1024px) {
  .c-hero__body h1 {
    font-size: 4rem;
    margin-bottom: 0.325em;
  }
}
.c-hero__body p,
.c-hero__body ol,
.c-hero__body ul {
  line-height: 1.6em;
}

.c-quote {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 40px;
}
.c-quote--carousel {
  padding: 7.2rem;
  align-items: center;
  grid-template-columns: 1fr;
}
@media only screen and (min-width: 768px) {
  .c-quote--carousel {
    grid-template-columns: auto 1fr;
  }
}
.c-quote__image--carousel {
  width: 120px;
  height: 120px;
  margin: 0 auto;
}
.c-quote__icon {
  width: 48px;
  height: 48px;
  fill: currentColor;
}
.c-quote__body {
  font-size: 1.9rem;
  line-height: 1.6em;
}
.c-quote__owner {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.7rem;
  color: #995a50;
}

.c-video > video {
  width: 100%;
}

.c-teaser {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 0.8666666667em;
  min-height: 100%;
}

.c-anchor {
  scroll-margin-top: 11.2rem;
}

@media only screen and (min-width: 1024px) {
  .c-anchor {
    scroll-margin-top: 12.8rem;
  }
}
.c-card {
  display: block;
}
.c-card__body {
  display: flex;
  flex-direction: column;
}
.c-card__body > *:last-child {
  margin-top: auto;
}
.c-card--blog {
  display: grid;
  grid-template-rows: auto 1fr;
}
.c-card--jobs {
  display: grid;
  grid-template-rows: 1fr;
}

.c-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding: 1rem 1.4rem;
  min-height: 4rem;
  background-color: transparent;
  border: 1px solid transparent;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.6rem;
  text-align: center;
  line-height: 1em;
  white-space: nowrap;
}
.c-button:hover {
  cursor: pointer;
}
.c-button--white {
  background-color: #fff;
  color: #020307;
}
.c-button--white:hover {
  background-color: #ebebeb;
  color: #020307;
}
.c-button--beige {
  background-color: #f9f1ef;
  color: #020307;
}
.c-button--beige:hover {
  background-color: #eed7d1;
  color: #020307;
}
.c-button--sand {
  background-color: #dab9a5;
  color: #020307;
}
.c-button--sand:hover {
  background-color: #cea388;
  color: #020307;
}
.c-button--oak {
  background-color: #995a50;
  color: #fff;
}
.c-button--oak:hover {
  background-color: #7e4a42;
  color: #fff;
}
.c-button--black {
  background-color: #020307;
  color: #fff;
}
.c-button--black:hover {
  background-color: black;
  color: #fff;
}
.c-button--ghost {
  background-color: transparent;
  border-color: #dab9a5;
}
.c-button--ghost:hover {
  border-color: #995a50;
}

.c-form__fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
.c-form__row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0 2.4rem;
}

.c-form__column--12 {
  grid-column: span 12;
}

.c-form__navigation__layout {
  display: grid;
  grid-template-columns: 1fr auto;
}

.c-input__label {
  display: block;
  margin-bottom: 0.4rem;
}

.c-input {
  margin-bottom: 1.6rem;
}

.c-input__field--text,
.c-input__field--textarea,
.c-input__field--dropdown,
.c-input__field--date,
.c-input__field--file {
  display: block;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dab9a5;
  margin: 0;
  padding: 1.2rem 1.6rem;
  border-radius: 0.4rem;
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  color: #020307;
}
.c-input__field--text:focus,
.c-input__field--textarea:focus,
.c-input__field--dropdown:focus,
.c-input__field--date:focus,
.c-input__field--file:focus {
  outline: none;
}

.c-input__field--text,
.c-input__field--dropdown,
.c-input__field--date {
  height: 4rem;
}

.c-input__field--dropdown {
  background-image: url(../img/chevron-down.svg);
  background-size: 1.6rem 1.6rem;
  background-repeat: no-repeat;
  background-position: top 1.2rem right 1.6rem;
  appearance: none;
}
.c-input__field--dropdown:hover {
  cursor: pointer;
}

.c-input__field--date {
  background-image: url(../img/calendar.svg);
  background-size: 1.6rem 1.6rem;
  background-repeat: no-repeat;
  background-position: top 1.2rem right 1.6rem;
}
.c-input__field--date:hover {
  cursor: pointer;
}

.c-input__field--textarea {
  height: 20rem;
  min-width: 100%;
  max-width: 100%;
}

.c-input__field--checkbox,
.c-input__field--radio {
  margin: 0;
  padding: 0;
  accent-color: #dab9a5;
}
.c-input__field--checkbox:hover,
.c-input__field--radio:hover {
  cursor: pointer;
}

.c-input__field__label--checkbox:hover,
.c-input__field__label--radio:hover {
  cursor: pointer;
}

.c-input__field__list--file {
  margin-top: 0.4rem;
}

.field-validation-error {
  display: block;
  margin-top: 0.4rem;
  font-size: smaller;
  color: #666;
  padding-left: 2rem;
  background-image: url(../img/exclamation.svg);
  background-size: 1.4rem 1.4rem;
  background-repeat: no-repeat;
  background-position: top 0.5rem left 0;
}

@media only screen and (min-width: 768px) {
  .c-form__column--1--md {
    grid-column: span 1;
  }
  .c-form__column--2--md {
    grid-column: span 2;
  }
  .c-form__column--3--md {
    grid-column: span 3;
  }
  .c-form__column--4--md {
    grid-column: span 4;
  }
  .c-form__column--5--md {
    grid-column: span 5;
  }
  .c-form__column--6--md {
    grid-column: span 6;
  }
  .c-form__column--7--md {
    grid-column: span 7;
  }
  .c-form__column--8--md {
    grid-column: span 8;
  }
  .c-form__column--9--md {
    grid-column: span 9;
  }
  .c-form__column--10--md {
    grid-column: span 10;
  }
  .c-form__column--11--md {
    grid-column: span 11;
  }
  .c-form__column--12--md {
    grid-column: span 12;
  }
}
.u-text a:not([class]):not([href*="tel:"]) {
  text-decoration: underline;
}
.u-text a:not([class]):not([href*="tel:"]):hover {
  text-decoration: none;
}
.u-text ul {
  list-style: none;
}
.u-text ul ol,
.u-text ul ul {
  padding-left: 3.2rem;
}
.u-text ul > li + li {
  margin-top: 0.8rem;
}
.u-text ul > li:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 1.6rem;
  height: 1.6rem;
  background-image: url("../img/checkmark.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top 0 left 0;
  margin-right: 0.6rem;
}
.u-text ol {
  list-style: none;
  counter-reset: alpha;
}
.u-text ol ol,
.u-text ol ul {
  padding-left: 3.2rem;
}
.u-text ol > li {
  counter-increment: alpha;
}
.u-text ol > li + li {
  margin-top: 0.8rem;
}
.u-text ol > li:before {
  content: counters(alpha, ".") ". ";
}

.u-m-auto {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}
.u-m-0 {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}
.u-m-4 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}
.u-m-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}
.u-m-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}
.u-m-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  margin-left: 2.4rem;
  margin-right: 2.4rem;
}
.u-m-32 {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  margin-left: 3.2rem;
  margin-right: 3.2rem;
}
.u-m-40 {
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-left: 4rem;
  margin-right: 4rem;
}
.u-m-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
  margin-left: 4.8rem;
  margin-right: 4.8rem;
}
.u-m-56 {
  margin-top: 5.6rem;
  margin-bottom: 5.6rem;
  margin-left: 5.6rem;
  margin-right: 5.6rem;
}
.u-m-64 {
  margin-top: 6.4rem;
  margin-bottom: 6.4rem;
  margin-left: 6.4rem;
  margin-right: 6.4rem;
}
.u-m-72 {
  margin-top: 7.2rem;
  margin-bottom: 7.2rem;
  margin-left: 7.2rem;
  margin-right: 7.2rem;
}
.u-m-80 {
  margin-top: 8rem;
  margin-bottom: 8rem;
  margin-left: 8rem;
  margin-right: 8rem;
}
.u-m-88 {
  margin-top: 8.8rem;
  margin-bottom: 8.8rem;
  margin-left: 8.8rem;
  margin-right: 8.8rem;
}
.u-m-96 {
  margin-top: 9.6rem;
  margin-bottom: 9.6rem;
  margin-left: 9.6rem;
  margin-right: 9.6rem;
}

.u-mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.u-mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.u-mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}
.u-mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}
.u-mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}
.u-mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem;
}
.u-mx-32 {
  margin-left: 3.2rem;
  margin-right: 3.2rem;
}
.u-mx-40 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.u-mx-48 {
  margin-left: 4.8rem;
  margin-right: 4.8rem;
}
.u-mx-56 {
  margin-left: 5.6rem;
  margin-right: 5.6rem;
}
.u-mx-64 {
  margin-left: 6.4rem;
  margin-right: 6.4rem;
}
.u-mx-72 {
  margin-left: 7.2rem;
  margin-right: 7.2rem;
}
.u-mx-80 {
  margin-left: 8rem;
  margin-right: 8rem;
}
.u-mx-88 {
  margin-left: 8.8rem;
  margin-right: 8.8rem;
}
.u-mx-96 {
  margin-left: 9.6rem;
  margin-right: 9.6rem;
}

.u-ml-auto {
  margin-left: auto;
}
.u-ml-0 {
  margin-left: 0;
}
.u-ml-4 {
  margin-left: 0.4rem;
}
.u-ml-8 {
  margin-left: 0.8rem;
}
.u-ml-16 {
  margin-left: 1.6rem;
}
.u-ml-24 {
  margin-left: 2.4rem;
}
.u-ml-32 {
  margin-left: 3.2rem;
}
.u-ml-40 {
  margin-left: 4rem;
}
.u-ml-48 {
  margin-left: 4.8rem;
}
.u-ml-56 {
  margin-left: 5.6rem;
}
.u-ml-64 {
  margin-left: 6.4rem;
}
.u-ml-72 {
  margin-left: 7.2rem;
}
.u-ml-80 {
  margin-left: 8rem;
}
.u-ml-88 {
  margin-left: 8.8rem;
}
.u-ml-96 {
  margin-left: 9.6rem;
}

.u-mr-auto {
  margin-right: auto;
}
.u-mr-0 {
  margin-right: 0;
}
.u-mr-4 {
  margin-right: 0.4rem;
}
.u-mr-8 {
  margin-right: 0.8rem;
}
.u-mr-16 {
  margin-right: 1.6rem;
}
.u-mr-24 {
  margin-right: 2.4rem;
}
.u-mr-32 {
  margin-right: 3.2rem;
}
.u-mr-40 {
  margin-right: 4rem;
}
.u-mr-48 {
  margin-right: 4.8rem;
}
.u-mr-56 {
  margin-right: 5.6rem;
}
.u-mr-64 {
  margin-right: 6.4rem;
}
.u-mr-72 {
  margin-right: 7.2rem;
}
.u-mr-80 {
  margin-right: 8rem;
}
.u-mr-88 {
  margin-right: 8.8rem;
}
.u-mr-96 {
  margin-right: 9.6rem;
}

.u-my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.u-my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.u-my-4 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}
.u-my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
.u-my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}
.u-my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}
.u-my-32 {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
}
.u-my-40 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.u-my-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
}
.u-my-56 {
  margin-top: 5.6rem;
  margin-bottom: 5.6rem;
}
.u-my-64 {
  margin-top: 6.4rem;
  margin-bottom: 6.4rem;
}
.u-my-72 {
  margin-top: 7.2rem;
  margin-bottom: 7.2rem;
}
.u-my-80 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.u-my-88 {
  margin-top: 8.8rem;
  margin-bottom: 8.8rem;
}
.u-my-96 {
  margin-top: 9.6rem;
  margin-bottom: 9.6rem;
}

.u-mt-auto {
  margin-top: auto;
}
.u-mt-0 {
  margin-top: 0;
}
.u-mt-4 {
  margin-top: 0.4rem;
}
.u-mt-8 {
  margin-top: 0.8rem;
}
.u-mt-16 {
  margin-top: 1.6rem;
}
.u-mt-24 {
  margin-top: 2.4rem;
}
.u-mt-32 {
  margin-top: 3.2rem;
}
.u-mt-40 {
  margin-top: 4rem;
}
.u-mt-48 {
  margin-top: 4.8rem;
}
.u-mt-56 {
  margin-top: 5.6rem;
}
.u-mt-64 {
  margin-top: 6.4rem;
}
.u-mt-72 {
  margin-top: 7.2rem;
}
.u-mt-80 {
  margin-top: 8rem;
}
.u-mt-88 {
  margin-top: 8.8rem;
}
.u-mt-96 {
  margin-top: 9.6rem;
}

.u-mb-auto {
  margin-bottom: auto;
}
.u-mb-0 {
  margin-bottom: 0;
}
.u-mb-4 {
  margin-bottom: 0.4rem;
}
.u-mb-8 {
  margin-bottom: 0.8rem;
}
.u-mb-16 {
  margin-bottom: 1.6rem;
}
.u-mb-24 {
  margin-bottom: 2.4rem;
}
.u-mb-32 {
  margin-bottom: 3.2rem;
}
.u-mb-40 {
  margin-bottom: 4rem;
}
.u-mb-48 {
  margin-bottom: 4.8rem;
}
.u-mb-56 {
  margin-bottom: 5.6rem;
}
.u-mb-64 {
  margin-bottom: 6.4rem;
}
.u-mb-72 {
  margin-bottom: 7.2rem;
}
.u-mb-80 {
  margin-bottom: 8rem;
}
.u-mb-88 {
  margin-bottom: 8.8rem;
}
.u-mb-96 {
  margin-bottom: 9.6rem;
}

@media only screen and (min-width: 480px) {
  .u-m-auto--xs {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .u-m-0--xs {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .u-m-4--xs {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .u-m-8--xs {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-m-16--xs {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-m-24--xs {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-m-32--xs {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-m-40--xs {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-m-48--xs {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-m-56--xs {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-m-64--xs {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-m-72--xs {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-m-80--xs {
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-m-88--xs {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-m-96--xs {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-mx-auto--xs {
    margin-left: auto;
    margin-right: auto;
  }
  .u-mx-0--xs {
    margin-left: 0;
    margin-right: 0;
  }
  .u-mx-4--xs {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .u-mx-8--xs {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-mx-16--xs {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-mx-24--xs {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-mx-32--xs {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-mx-40--xs {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-mx-48--xs {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-mx-56--xs {
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-mx-64--xs {
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-mx-72--xs {
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-mx-80--xs {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-mx-88--xs {
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-mx-96--xs {
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-ml-auto--xs {
    margin-left: auto;
  }
  .u-ml-0--xs {
    margin-left: 0;
  }
  .u-ml-4--xs {
    margin-left: 0.4rem;
  }
  .u-ml-8--xs {
    margin-left: 0.8rem;
  }
  .u-ml-16--xs {
    margin-left: 1.6rem;
  }
  .u-ml-24--xs {
    margin-left: 2.4rem;
  }
  .u-ml-32--xs {
    margin-left: 3.2rem;
  }
  .u-ml-40--xs {
    margin-left: 4rem;
  }
  .u-ml-48--xs {
    margin-left: 4.8rem;
  }
  .u-ml-56--xs {
    margin-left: 5.6rem;
  }
  .u-ml-64--xs {
    margin-left: 6.4rem;
  }
  .u-ml-72--xs {
    margin-left: 7.2rem;
  }
  .u-ml-80--xs {
    margin-left: 8rem;
  }
  .u-ml-88--xs {
    margin-left: 8.8rem;
  }
  .u-ml-96--xs {
    margin-left: 9.6rem;
  }
  .u-mr-auto--xs {
    margin-right: auto;
  }
  .u-mr-0--xs {
    margin-right: 0;
  }
  .u-mr-4--xs {
    margin-right: 0.4rem;
  }
  .u-mr-8--xs {
    margin-right: 0.8rem;
  }
  .u-mr-16--xs {
    margin-right: 1.6rem;
  }
  .u-mr-24--xs {
    margin-right: 2.4rem;
  }
  .u-mr-32--xs {
    margin-right: 3.2rem;
  }
  .u-mr-40--xs {
    margin-right: 4rem;
  }
  .u-mr-48--xs {
    margin-right: 4.8rem;
  }
  .u-mr-56--xs {
    margin-right: 5.6rem;
  }
  .u-mr-64--xs {
    margin-right: 6.4rem;
  }
  .u-mr-72--xs {
    margin-right: 7.2rem;
  }
  .u-mr-80--xs {
    margin-right: 8rem;
  }
  .u-mr-88--xs {
    margin-right: 8.8rem;
  }
  .u-mr-96--xs {
    margin-right: 9.6rem;
  }
  .u-my-auto--xs {
    margin-top: auto;
    margin-bottom: auto;
  }
  .u-my-0--xs {
    margin-top: 0;
    margin-bottom: 0;
  }
  .u-my-4--xs {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }
  .u-my-8--xs {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .u-my-16--xs {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .u-my-24--xs {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .u-my-32--xs {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .u-my-40--xs {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .u-my-48--xs {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
  .u-my-56--xs {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
  }
  .u-my-64--xs {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
  }
  .u-my-72--xs {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
  }
  .u-my-80--xs {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .u-my-88--xs {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
  }
  .u-my-96--xs {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
  }
  .u-mt-auto--xs {
    margin-top: auto;
  }
  .u-mt-0--xs {
    margin-top: 0;
  }
  .u-mt-4--xs {
    margin-top: 0.4rem;
  }
  .u-mt-8--xs {
    margin-top: 0.8rem;
  }
  .u-mt-16--xs {
    margin-top: 1.6rem;
  }
  .u-mt-24--xs {
    margin-top: 2.4rem;
  }
  .u-mt-32--xs {
    margin-top: 3.2rem;
  }
  .u-mt-40--xs {
    margin-top: 4rem;
  }
  .u-mt-48--xs {
    margin-top: 4.8rem;
  }
  .u-mt-56--xs {
    margin-top: 5.6rem;
  }
  .u-mt-64--xs {
    margin-top: 6.4rem;
  }
  .u-mt-72--xs {
    margin-top: 7.2rem;
  }
  .u-mt-80--xs {
    margin-top: 8rem;
  }
  .u-mt-88--xs {
    margin-top: 8.8rem;
  }
  .u-mt-96--xs {
    margin-top: 9.6rem;
  }
  .u-mb-auto--xs {
    margin-bottom: auto;
  }
  .u-mb-0--xs {
    margin-bottom: 0;
  }
  .u-mb-4--xs {
    margin-bottom: 0.4rem;
  }
  .u-mb-8--xs {
    margin-bottom: 0.8rem;
  }
  .u-mb-16--xs {
    margin-bottom: 1.6rem;
  }
  .u-mb-24--xs {
    margin-bottom: 2.4rem;
  }
  .u-mb-32--xs {
    margin-bottom: 3.2rem;
  }
  .u-mb-40--xs {
    margin-bottom: 4rem;
  }
  .u-mb-48--xs {
    margin-bottom: 4.8rem;
  }
  .u-mb-56--xs {
    margin-bottom: 5.6rem;
  }
  .u-mb-64--xs {
    margin-bottom: 6.4rem;
  }
  .u-mb-72--xs {
    margin-bottom: 7.2rem;
  }
  .u-mb-80--xs {
    margin-bottom: 8rem;
  }
  .u-mb-88--xs {
    margin-bottom: 8.8rem;
  }
  .u-mb-96--xs {
    margin-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 640px) {
  .u-m-auto--sm {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .u-m-0--sm {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .u-m-4--sm {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .u-m-8--sm {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-m-16--sm {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-m-24--sm {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-m-32--sm {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-m-40--sm {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-m-48--sm {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-m-56--sm {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-m-64--sm {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-m-72--sm {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-m-80--sm {
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-m-88--sm {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-m-96--sm {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-mx-auto--sm {
    margin-left: auto;
    margin-right: auto;
  }
  .u-mx-0--sm {
    margin-left: 0;
    margin-right: 0;
  }
  .u-mx-4--sm {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .u-mx-8--sm {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-mx-16--sm {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-mx-24--sm {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-mx-32--sm {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-mx-40--sm {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-mx-48--sm {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-mx-56--sm {
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-mx-64--sm {
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-mx-72--sm {
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-mx-80--sm {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-mx-88--sm {
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-mx-96--sm {
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-ml-auto--sm {
    margin-left: auto;
  }
  .u-ml-0--sm {
    margin-left: 0;
  }
  .u-ml-4--sm {
    margin-left: 0.4rem;
  }
  .u-ml-8--sm {
    margin-left: 0.8rem;
  }
  .u-ml-16--sm {
    margin-left: 1.6rem;
  }
  .u-ml-24--sm {
    margin-left: 2.4rem;
  }
  .u-ml-32--sm {
    margin-left: 3.2rem;
  }
  .u-ml-40--sm {
    margin-left: 4rem;
  }
  .u-ml-48--sm {
    margin-left: 4.8rem;
  }
  .u-ml-56--sm {
    margin-left: 5.6rem;
  }
  .u-ml-64--sm {
    margin-left: 6.4rem;
  }
  .u-ml-72--sm {
    margin-left: 7.2rem;
  }
  .u-ml-80--sm {
    margin-left: 8rem;
  }
  .u-ml-88--sm {
    margin-left: 8.8rem;
  }
  .u-ml-96--sm {
    margin-left: 9.6rem;
  }
  .u-mr-auto--sm {
    margin-right: auto;
  }
  .u-mr-0--sm {
    margin-right: 0;
  }
  .u-mr-4--sm {
    margin-right: 0.4rem;
  }
  .u-mr-8--sm {
    margin-right: 0.8rem;
  }
  .u-mr-16--sm {
    margin-right: 1.6rem;
  }
  .u-mr-24--sm {
    margin-right: 2.4rem;
  }
  .u-mr-32--sm {
    margin-right: 3.2rem;
  }
  .u-mr-40--sm {
    margin-right: 4rem;
  }
  .u-mr-48--sm {
    margin-right: 4.8rem;
  }
  .u-mr-56--sm {
    margin-right: 5.6rem;
  }
  .u-mr-64--sm {
    margin-right: 6.4rem;
  }
  .u-mr-72--sm {
    margin-right: 7.2rem;
  }
  .u-mr-80--sm {
    margin-right: 8rem;
  }
  .u-mr-88--sm {
    margin-right: 8.8rem;
  }
  .u-mr-96--sm {
    margin-right: 9.6rem;
  }
  .u-my-auto--sm {
    margin-top: auto;
    margin-bottom: auto;
  }
  .u-my-0--sm {
    margin-top: 0;
    margin-bottom: 0;
  }
  .u-my-4--sm {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }
  .u-my-8--sm {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .u-my-16--sm {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .u-my-24--sm {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .u-my-32--sm {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .u-my-40--sm {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .u-my-48--sm {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
  .u-my-56--sm {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
  }
  .u-my-64--sm {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
  }
  .u-my-72--sm {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
  }
  .u-my-80--sm {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .u-my-88--sm {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
  }
  .u-my-96--sm {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
  }
  .u-mt-auto--sm {
    margin-top: auto;
  }
  .u-mt-0--sm {
    margin-top: 0;
  }
  .u-mt-4--sm {
    margin-top: 0.4rem;
  }
  .u-mt-8--sm {
    margin-top: 0.8rem;
  }
  .u-mt-16--sm {
    margin-top: 1.6rem;
  }
  .u-mt-24--sm {
    margin-top: 2.4rem;
  }
  .u-mt-32--sm {
    margin-top: 3.2rem;
  }
  .u-mt-40--sm {
    margin-top: 4rem;
  }
  .u-mt-48--sm {
    margin-top: 4.8rem;
  }
  .u-mt-56--sm {
    margin-top: 5.6rem;
  }
  .u-mt-64--sm {
    margin-top: 6.4rem;
  }
  .u-mt-72--sm {
    margin-top: 7.2rem;
  }
  .u-mt-80--sm {
    margin-top: 8rem;
  }
  .u-mt-88--sm {
    margin-top: 8.8rem;
  }
  .u-mt-96--sm {
    margin-top: 9.6rem;
  }
  .u-mb-auto--sm {
    margin-bottom: auto;
  }
  .u-mb-0--sm {
    margin-bottom: 0;
  }
  .u-mb-4--sm {
    margin-bottom: 0.4rem;
  }
  .u-mb-8--sm {
    margin-bottom: 0.8rem;
  }
  .u-mb-16--sm {
    margin-bottom: 1.6rem;
  }
  .u-mb-24--sm {
    margin-bottom: 2.4rem;
  }
  .u-mb-32--sm {
    margin-bottom: 3.2rem;
  }
  .u-mb-40--sm {
    margin-bottom: 4rem;
  }
  .u-mb-48--sm {
    margin-bottom: 4.8rem;
  }
  .u-mb-56--sm {
    margin-bottom: 5.6rem;
  }
  .u-mb-64--sm {
    margin-bottom: 6.4rem;
  }
  .u-mb-72--sm {
    margin-bottom: 7.2rem;
  }
  .u-mb-80--sm {
    margin-bottom: 8rem;
  }
  .u-mb-88--sm {
    margin-bottom: 8.8rem;
  }
  .u-mb-96--sm {
    margin-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 768px) {
  .u-m-auto--md {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .u-m-0--md {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .u-m-4--md {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .u-m-8--md {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-m-16--md {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-m-24--md {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-m-32--md {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-m-40--md {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-m-48--md {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-m-56--md {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-m-64--md {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-m-72--md {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-m-80--md {
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-m-88--md {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-m-96--md {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-mx-auto--md {
    margin-left: auto;
    margin-right: auto;
  }
  .u-mx-0--md {
    margin-left: 0;
    margin-right: 0;
  }
  .u-mx-4--md {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .u-mx-8--md {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-mx-16--md {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-mx-24--md {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-mx-32--md {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-mx-40--md {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-mx-48--md {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-mx-56--md {
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-mx-64--md {
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-mx-72--md {
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-mx-80--md {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-mx-88--md {
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-mx-96--md {
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-ml-auto--md {
    margin-left: auto;
  }
  .u-ml-0--md {
    margin-left: 0;
  }
  .u-ml-4--md {
    margin-left: 0.4rem;
  }
  .u-ml-8--md {
    margin-left: 0.8rem;
  }
  .u-ml-16--md {
    margin-left: 1.6rem;
  }
  .u-ml-24--md {
    margin-left: 2.4rem;
  }
  .u-ml-32--md {
    margin-left: 3.2rem;
  }
  .u-ml-40--md {
    margin-left: 4rem;
  }
  .u-ml-48--md {
    margin-left: 4.8rem;
  }
  .u-ml-56--md {
    margin-left: 5.6rem;
  }
  .u-ml-64--md {
    margin-left: 6.4rem;
  }
  .u-ml-72--md {
    margin-left: 7.2rem;
  }
  .u-ml-80--md {
    margin-left: 8rem;
  }
  .u-ml-88--md {
    margin-left: 8.8rem;
  }
  .u-ml-96--md {
    margin-left: 9.6rem;
  }
  .u-mr-auto--md {
    margin-right: auto;
  }
  .u-mr-0--md {
    margin-right: 0;
  }
  .u-mr-4--md {
    margin-right: 0.4rem;
  }
  .u-mr-8--md {
    margin-right: 0.8rem;
  }
  .u-mr-16--md {
    margin-right: 1.6rem;
  }
  .u-mr-24--md {
    margin-right: 2.4rem;
  }
  .u-mr-32--md {
    margin-right: 3.2rem;
  }
  .u-mr-40--md {
    margin-right: 4rem;
  }
  .u-mr-48--md {
    margin-right: 4.8rem;
  }
  .u-mr-56--md {
    margin-right: 5.6rem;
  }
  .u-mr-64--md {
    margin-right: 6.4rem;
  }
  .u-mr-72--md {
    margin-right: 7.2rem;
  }
  .u-mr-80--md {
    margin-right: 8rem;
  }
  .u-mr-88--md {
    margin-right: 8.8rem;
  }
  .u-mr-96--md {
    margin-right: 9.6rem;
  }
  .u-my-auto--md {
    margin-top: auto;
    margin-bottom: auto;
  }
  .u-my-0--md {
    margin-top: 0;
    margin-bottom: 0;
  }
  .u-my-4--md {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }
  .u-my-8--md {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .u-my-16--md {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .u-my-24--md {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .u-my-32--md {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .u-my-40--md {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .u-my-48--md {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
  .u-my-56--md {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
  }
  .u-my-64--md {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
  }
  .u-my-72--md {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
  }
  .u-my-80--md {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .u-my-88--md {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
  }
  .u-my-96--md {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
  }
  .u-mt-auto--md {
    margin-top: auto;
  }
  .u-mt-0--md {
    margin-top: 0;
  }
  .u-mt-4--md {
    margin-top: 0.4rem;
  }
  .u-mt-8--md {
    margin-top: 0.8rem;
  }
  .u-mt-16--md {
    margin-top: 1.6rem;
  }
  .u-mt-24--md {
    margin-top: 2.4rem;
  }
  .u-mt-32--md {
    margin-top: 3.2rem;
  }
  .u-mt-40--md {
    margin-top: 4rem;
  }
  .u-mt-48--md {
    margin-top: 4.8rem;
  }
  .u-mt-56--md {
    margin-top: 5.6rem;
  }
  .u-mt-64--md {
    margin-top: 6.4rem;
  }
  .u-mt-72--md {
    margin-top: 7.2rem;
  }
  .u-mt-80--md {
    margin-top: 8rem;
  }
  .u-mt-88--md {
    margin-top: 8.8rem;
  }
  .u-mt-96--md {
    margin-top: 9.6rem;
  }
  .u-mb-auto--md {
    margin-bottom: auto;
  }
  .u-mb-0--md {
    margin-bottom: 0;
  }
  .u-mb-4--md {
    margin-bottom: 0.4rem;
  }
  .u-mb-8--md {
    margin-bottom: 0.8rem;
  }
  .u-mb-16--md {
    margin-bottom: 1.6rem;
  }
  .u-mb-24--md {
    margin-bottom: 2.4rem;
  }
  .u-mb-32--md {
    margin-bottom: 3.2rem;
  }
  .u-mb-40--md {
    margin-bottom: 4rem;
  }
  .u-mb-48--md {
    margin-bottom: 4.8rem;
  }
  .u-mb-56--md {
    margin-bottom: 5.6rem;
  }
  .u-mb-64--md {
    margin-bottom: 6.4rem;
  }
  .u-mb-72--md {
    margin-bottom: 7.2rem;
  }
  .u-mb-80--md {
    margin-bottom: 8rem;
  }
  .u-mb-88--md {
    margin-bottom: 8.8rem;
  }
  .u-mb-96--md {
    margin-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .u-m-auto--lg {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .u-m-0--lg {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .u-m-4--lg {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .u-m-8--lg {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-m-16--lg {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-m-24--lg {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-m-32--lg {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-m-40--lg {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-m-48--lg {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-m-56--lg {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-m-64--lg {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-m-72--lg {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-m-80--lg {
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-m-88--lg {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-m-96--lg {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-mx-auto--lg {
    margin-left: auto;
    margin-right: auto;
  }
  .u-mx-0--lg {
    margin-left: 0;
    margin-right: 0;
  }
  .u-mx-4--lg {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .u-mx-8--lg {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-mx-16--lg {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-mx-24--lg {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-mx-32--lg {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-mx-40--lg {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-mx-48--lg {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-mx-56--lg {
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-mx-64--lg {
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-mx-72--lg {
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-mx-80--lg {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-mx-88--lg {
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-mx-96--lg {
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-ml-auto--lg {
    margin-left: auto;
  }
  .u-ml-0--lg {
    margin-left: 0;
  }
  .u-ml-4--lg {
    margin-left: 0.4rem;
  }
  .u-ml-8--lg {
    margin-left: 0.8rem;
  }
  .u-ml-16--lg {
    margin-left: 1.6rem;
  }
  .u-ml-24--lg {
    margin-left: 2.4rem;
  }
  .u-ml-32--lg {
    margin-left: 3.2rem;
  }
  .u-ml-40--lg {
    margin-left: 4rem;
  }
  .u-ml-48--lg {
    margin-left: 4.8rem;
  }
  .u-ml-56--lg {
    margin-left: 5.6rem;
  }
  .u-ml-64--lg {
    margin-left: 6.4rem;
  }
  .u-ml-72--lg {
    margin-left: 7.2rem;
  }
  .u-ml-80--lg {
    margin-left: 8rem;
  }
  .u-ml-88--lg {
    margin-left: 8.8rem;
  }
  .u-ml-96--lg {
    margin-left: 9.6rem;
  }
  .u-mr-auto--lg {
    margin-right: auto;
  }
  .u-mr-0--lg {
    margin-right: 0;
  }
  .u-mr-4--lg {
    margin-right: 0.4rem;
  }
  .u-mr-8--lg {
    margin-right: 0.8rem;
  }
  .u-mr-16--lg {
    margin-right: 1.6rem;
  }
  .u-mr-24--lg {
    margin-right: 2.4rem;
  }
  .u-mr-32--lg {
    margin-right: 3.2rem;
  }
  .u-mr-40--lg {
    margin-right: 4rem;
  }
  .u-mr-48--lg {
    margin-right: 4.8rem;
  }
  .u-mr-56--lg {
    margin-right: 5.6rem;
  }
  .u-mr-64--lg {
    margin-right: 6.4rem;
  }
  .u-mr-72--lg {
    margin-right: 7.2rem;
  }
  .u-mr-80--lg {
    margin-right: 8rem;
  }
  .u-mr-88--lg {
    margin-right: 8.8rem;
  }
  .u-mr-96--lg {
    margin-right: 9.6rem;
  }
  .u-my-auto--lg {
    margin-top: auto;
    margin-bottom: auto;
  }
  .u-my-0--lg {
    margin-top: 0;
    margin-bottom: 0;
  }
  .u-my-4--lg {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }
  .u-my-8--lg {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .u-my-16--lg {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .u-my-24--lg {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .u-my-32--lg {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .u-my-40--lg {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .u-my-48--lg {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
  .u-my-56--lg {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
  }
  .u-my-64--lg {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
  }
  .u-my-72--lg {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
  }
  .u-my-80--lg {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .u-my-88--lg {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
  }
  .u-my-96--lg {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
  }
  .u-mt-auto--lg {
    margin-top: auto;
  }
  .u-mt-0--lg {
    margin-top: 0;
  }
  .u-mt-4--lg {
    margin-top: 0.4rem;
  }
  .u-mt-8--lg {
    margin-top: 0.8rem;
  }
  .u-mt-16--lg {
    margin-top: 1.6rem;
  }
  .u-mt-24--lg {
    margin-top: 2.4rem;
  }
  .u-mt-32--lg {
    margin-top: 3.2rem;
  }
  .u-mt-40--lg {
    margin-top: 4rem;
  }
  .u-mt-48--lg {
    margin-top: 4.8rem;
  }
  .u-mt-56--lg {
    margin-top: 5.6rem;
  }
  .u-mt-64--lg {
    margin-top: 6.4rem;
  }
  .u-mt-72--lg {
    margin-top: 7.2rem;
  }
  .u-mt-80--lg {
    margin-top: 8rem;
  }
  .u-mt-88--lg {
    margin-top: 8.8rem;
  }
  .u-mt-96--lg {
    margin-top: 9.6rem;
  }
  .u-mb-auto--lg {
    margin-bottom: auto;
  }
  .u-mb-0--lg {
    margin-bottom: 0;
  }
  .u-mb-4--lg {
    margin-bottom: 0.4rem;
  }
  .u-mb-8--lg {
    margin-bottom: 0.8rem;
  }
  .u-mb-16--lg {
    margin-bottom: 1.6rem;
  }
  .u-mb-24--lg {
    margin-bottom: 2.4rem;
  }
  .u-mb-32--lg {
    margin-bottom: 3.2rem;
  }
  .u-mb-40--lg {
    margin-bottom: 4rem;
  }
  .u-mb-48--lg {
    margin-bottom: 4.8rem;
  }
  .u-mb-56--lg {
    margin-bottom: 5.6rem;
  }
  .u-mb-64--lg {
    margin-bottom: 6.4rem;
  }
  .u-mb-72--lg {
    margin-bottom: 7.2rem;
  }
  .u-mb-80--lg {
    margin-bottom: 8rem;
  }
  .u-mb-88--lg {
    margin-bottom: 8.8rem;
  }
  .u-mb-96--lg {
    margin-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 1280px) {
  .u-m-auto--xl {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .u-m-0--xl {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .u-m-4--xl {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .u-m-8--xl {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-m-16--xl {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-m-24--xl {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-m-32--xl {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-m-40--xl {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-m-48--xl {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-m-56--xl {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-m-64--xl {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-m-72--xl {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-m-80--xl {
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-m-88--xl {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-m-96--xl {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-mx-auto--xl {
    margin-left: auto;
    margin-right: auto;
  }
  .u-mx-0--xl {
    margin-left: 0;
    margin-right: 0;
  }
  .u-mx-4--xl {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .u-mx-8--xl {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-mx-16--xl {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-mx-24--xl {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-mx-32--xl {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-mx-40--xl {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-mx-48--xl {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-mx-56--xl {
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-mx-64--xl {
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-mx-72--xl {
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-mx-80--xl {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-mx-88--xl {
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-mx-96--xl {
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-ml-auto--xl {
    margin-left: auto;
  }
  .u-ml-0--xl {
    margin-left: 0;
  }
  .u-ml-4--xl {
    margin-left: 0.4rem;
  }
  .u-ml-8--xl {
    margin-left: 0.8rem;
  }
  .u-ml-16--xl {
    margin-left: 1.6rem;
  }
  .u-ml-24--xl {
    margin-left: 2.4rem;
  }
  .u-ml-32--xl {
    margin-left: 3.2rem;
  }
  .u-ml-40--xl {
    margin-left: 4rem;
  }
  .u-ml-48--xl {
    margin-left: 4.8rem;
  }
  .u-ml-56--xl {
    margin-left: 5.6rem;
  }
  .u-ml-64--xl {
    margin-left: 6.4rem;
  }
  .u-ml-72--xl {
    margin-left: 7.2rem;
  }
  .u-ml-80--xl {
    margin-left: 8rem;
  }
  .u-ml-88--xl {
    margin-left: 8.8rem;
  }
  .u-ml-96--xl {
    margin-left: 9.6rem;
  }
  .u-mr-auto--xl {
    margin-right: auto;
  }
  .u-mr-0--xl {
    margin-right: 0;
  }
  .u-mr-4--xl {
    margin-right: 0.4rem;
  }
  .u-mr-8--xl {
    margin-right: 0.8rem;
  }
  .u-mr-16--xl {
    margin-right: 1.6rem;
  }
  .u-mr-24--xl {
    margin-right: 2.4rem;
  }
  .u-mr-32--xl {
    margin-right: 3.2rem;
  }
  .u-mr-40--xl {
    margin-right: 4rem;
  }
  .u-mr-48--xl {
    margin-right: 4.8rem;
  }
  .u-mr-56--xl {
    margin-right: 5.6rem;
  }
  .u-mr-64--xl {
    margin-right: 6.4rem;
  }
  .u-mr-72--xl {
    margin-right: 7.2rem;
  }
  .u-mr-80--xl {
    margin-right: 8rem;
  }
  .u-mr-88--xl {
    margin-right: 8.8rem;
  }
  .u-mr-96--xl {
    margin-right: 9.6rem;
  }
  .u-my-auto--xl {
    margin-top: auto;
    margin-bottom: auto;
  }
  .u-my-0--xl {
    margin-top: 0;
    margin-bottom: 0;
  }
  .u-my-4--xl {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }
  .u-my-8--xl {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .u-my-16--xl {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .u-my-24--xl {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .u-my-32--xl {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .u-my-40--xl {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .u-my-48--xl {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
  .u-my-56--xl {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
  }
  .u-my-64--xl {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
  }
  .u-my-72--xl {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
  }
  .u-my-80--xl {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .u-my-88--xl {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
  }
  .u-my-96--xl {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
  }
  .u-mt-auto--xl {
    margin-top: auto;
  }
  .u-mt-0--xl {
    margin-top: 0;
  }
  .u-mt-4--xl {
    margin-top: 0.4rem;
  }
  .u-mt-8--xl {
    margin-top: 0.8rem;
  }
  .u-mt-16--xl {
    margin-top: 1.6rem;
  }
  .u-mt-24--xl {
    margin-top: 2.4rem;
  }
  .u-mt-32--xl {
    margin-top: 3.2rem;
  }
  .u-mt-40--xl {
    margin-top: 4rem;
  }
  .u-mt-48--xl {
    margin-top: 4.8rem;
  }
  .u-mt-56--xl {
    margin-top: 5.6rem;
  }
  .u-mt-64--xl {
    margin-top: 6.4rem;
  }
  .u-mt-72--xl {
    margin-top: 7.2rem;
  }
  .u-mt-80--xl {
    margin-top: 8rem;
  }
  .u-mt-88--xl {
    margin-top: 8.8rem;
  }
  .u-mt-96--xl {
    margin-top: 9.6rem;
  }
  .u-mb-auto--xl {
    margin-bottom: auto;
  }
  .u-mb-0--xl {
    margin-bottom: 0;
  }
  .u-mb-4--xl {
    margin-bottom: 0.4rem;
  }
  .u-mb-8--xl {
    margin-bottom: 0.8rem;
  }
  .u-mb-16--xl {
    margin-bottom: 1.6rem;
  }
  .u-mb-24--xl {
    margin-bottom: 2.4rem;
  }
  .u-mb-32--xl {
    margin-bottom: 3.2rem;
  }
  .u-mb-40--xl {
    margin-bottom: 4rem;
  }
  .u-mb-48--xl {
    margin-bottom: 4.8rem;
  }
  .u-mb-56--xl {
    margin-bottom: 5.6rem;
  }
  .u-mb-64--xl {
    margin-bottom: 6.4rem;
  }
  .u-mb-72--xl {
    margin-bottom: 7.2rem;
  }
  .u-mb-80--xl {
    margin-bottom: 8rem;
  }
  .u-mb-88--xl {
    margin-bottom: 8.8rem;
  }
  .u-mb-96--xl {
    margin-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 1920px) {
  .u-m-auto--xxl {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .u-m-0--xxl {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .u-m-4--xxl {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .u-m-8--xxl {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-m-16--xxl {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-m-24--xxl {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-m-32--xxl {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-m-40--xxl {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-m-48--xxl {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-m-56--xxl {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-m-64--xxl {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-m-72--xxl {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-m-80--xxl {
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-m-88--xxl {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-m-96--xxl {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-mx-auto--xxl {
    margin-left: auto;
    margin-right: auto;
  }
  .u-mx-0--xxl {
    margin-left: 0;
    margin-right: 0;
  }
  .u-mx-4--xxl {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .u-mx-8--xxl {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-mx-16--xxl {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-mx-24--xxl {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-mx-32--xxl {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-mx-40--xxl {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-mx-48--xxl {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-mx-56--xxl {
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-mx-64--xxl {
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-mx-72--xxl {
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-mx-80--xxl {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-mx-88--xxl {
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-mx-96--xxl {
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-ml-auto--xxl {
    margin-left: auto;
  }
  .u-ml-0--xxl {
    margin-left: 0;
  }
  .u-ml-4--xxl {
    margin-left: 0.4rem;
  }
  .u-ml-8--xxl {
    margin-left: 0.8rem;
  }
  .u-ml-16--xxl {
    margin-left: 1.6rem;
  }
  .u-ml-24--xxl {
    margin-left: 2.4rem;
  }
  .u-ml-32--xxl {
    margin-left: 3.2rem;
  }
  .u-ml-40--xxl {
    margin-left: 4rem;
  }
  .u-ml-48--xxl {
    margin-left: 4.8rem;
  }
  .u-ml-56--xxl {
    margin-left: 5.6rem;
  }
  .u-ml-64--xxl {
    margin-left: 6.4rem;
  }
  .u-ml-72--xxl {
    margin-left: 7.2rem;
  }
  .u-ml-80--xxl {
    margin-left: 8rem;
  }
  .u-ml-88--xxl {
    margin-left: 8.8rem;
  }
  .u-ml-96--xxl {
    margin-left: 9.6rem;
  }
  .u-mr-auto--xxl {
    margin-right: auto;
  }
  .u-mr-0--xxl {
    margin-right: 0;
  }
  .u-mr-4--xxl {
    margin-right: 0.4rem;
  }
  .u-mr-8--xxl {
    margin-right: 0.8rem;
  }
  .u-mr-16--xxl {
    margin-right: 1.6rem;
  }
  .u-mr-24--xxl {
    margin-right: 2.4rem;
  }
  .u-mr-32--xxl {
    margin-right: 3.2rem;
  }
  .u-mr-40--xxl {
    margin-right: 4rem;
  }
  .u-mr-48--xxl {
    margin-right: 4.8rem;
  }
  .u-mr-56--xxl {
    margin-right: 5.6rem;
  }
  .u-mr-64--xxl {
    margin-right: 6.4rem;
  }
  .u-mr-72--xxl {
    margin-right: 7.2rem;
  }
  .u-mr-80--xxl {
    margin-right: 8rem;
  }
  .u-mr-88--xxl {
    margin-right: 8.8rem;
  }
  .u-mr-96--xxl {
    margin-right: 9.6rem;
  }
  .u-my-auto--xxl {
    margin-top: auto;
    margin-bottom: auto;
  }
  .u-my-0--xxl {
    margin-top: 0;
    margin-bottom: 0;
  }
  .u-my-4--xxl {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }
  .u-my-8--xxl {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .u-my-16--xxl {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .u-my-24--xxl {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .u-my-32--xxl {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .u-my-40--xxl {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .u-my-48--xxl {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
  .u-my-56--xxl {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
  }
  .u-my-64--xxl {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
  }
  .u-my-72--xxl {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
  }
  .u-my-80--xxl {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .u-my-88--xxl {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
  }
  .u-my-96--xxl {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
  }
  .u-mt-auto--xxl {
    margin-top: auto;
  }
  .u-mt-0--xxl {
    margin-top: 0;
  }
  .u-mt-4--xxl {
    margin-top: 0.4rem;
  }
  .u-mt-8--xxl {
    margin-top: 0.8rem;
  }
  .u-mt-16--xxl {
    margin-top: 1.6rem;
  }
  .u-mt-24--xxl {
    margin-top: 2.4rem;
  }
  .u-mt-32--xxl {
    margin-top: 3.2rem;
  }
  .u-mt-40--xxl {
    margin-top: 4rem;
  }
  .u-mt-48--xxl {
    margin-top: 4.8rem;
  }
  .u-mt-56--xxl {
    margin-top: 5.6rem;
  }
  .u-mt-64--xxl {
    margin-top: 6.4rem;
  }
  .u-mt-72--xxl {
    margin-top: 7.2rem;
  }
  .u-mt-80--xxl {
    margin-top: 8rem;
  }
  .u-mt-88--xxl {
    margin-top: 8.8rem;
  }
  .u-mt-96--xxl {
    margin-top: 9.6rem;
  }
  .u-mb-auto--xxl {
    margin-bottom: auto;
  }
  .u-mb-0--xxl {
    margin-bottom: 0;
  }
  .u-mb-4--xxl {
    margin-bottom: 0.4rem;
  }
  .u-mb-8--xxl {
    margin-bottom: 0.8rem;
  }
  .u-mb-16--xxl {
    margin-bottom: 1.6rem;
  }
  .u-mb-24--xxl {
    margin-bottom: 2.4rem;
  }
  .u-mb-32--xxl {
    margin-bottom: 3.2rem;
  }
  .u-mb-40--xxl {
    margin-bottom: 4rem;
  }
  .u-mb-48--xxl {
    margin-bottom: 4.8rem;
  }
  .u-mb-56--xxl {
    margin-bottom: 5.6rem;
  }
  .u-mb-64--xxl {
    margin-bottom: 6.4rem;
  }
  .u-mb-72--xxl {
    margin-bottom: 7.2rem;
  }
  .u-mb-80--xxl {
    margin-bottom: 8rem;
  }
  .u-mb-88--xxl {
    margin-bottom: 8.8rem;
  }
  .u-mb-96--xxl {
    margin-bottom: 9.6rem;
  }
}
.u-p-auto {
  padding-top: auto;
  padding-bottom: auto;
  padding-left: auto;
  padding-right: auto;
}
.u-p-0 {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
.u-p-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
.u-p-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.u-p-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.u-p-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.u-p-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
}
.u-p-40 {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
}
.u-p-48 {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
  padding-left: 4.8rem;
  padding-right: 4.8rem;
}
.u-p-56 {
  padding-top: 5.6rem;
  padding-bottom: 5.6rem;
  padding-left: 5.6rem;
  padding-right: 5.6rem;
}
.u-p-64 {
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;
  padding-left: 6.4rem;
  padding-right: 6.4rem;
}
.u-p-72 {
  padding-top: 7.2rem;
  padding-bottom: 7.2rem;
  padding-left: 7.2rem;
  padding-right: 7.2rem;
}
.u-p-80 {
  padding-top: 8rem;
  padding-bottom: 8rem;
  padding-left: 8rem;
  padding-right: 8rem;
}
.u-p-88 {
  padding-top: 8.8rem;
  padding-bottom: 8.8rem;
  padding-left: 8.8rem;
  padding-right: 8.8rem;
}
.u-p-96 {
  padding-top: 9.6rem;
  padding-bottom: 9.6rem;
  padding-left: 9.6rem;
  padding-right: 9.6rem;
}

.u-px-auto {
  padding-left: auto;
  padding-right: auto;
}
.u-px-0 {
  padding-left: 0;
  padding-right: 0;
}
.u-px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
.u-px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.u-px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.u-px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.u-px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem;
}
.u-px-40 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.u-px-48 {
  padding-left: 4.8rem;
  padding-right: 4.8rem;
}
.u-px-56 {
  padding-left: 5.6rem;
  padding-right: 5.6rem;
}
.u-px-64 {
  padding-left: 6.4rem;
  padding-right: 6.4rem;
}
.u-px-72 {
  padding-left: 7.2rem;
  padding-right: 7.2rem;
}
.u-px-80 {
  padding-left: 8rem;
  padding-right: 8rem;
}
.u-px-88 {
  padding-left: 8.8rem;
  padding-right: 8.8rem;
}
.u-px-96 {
  padding-left: 9.6rem;
  padding-right: 9.6rem;
}

.u-pl-auto {
  padding-left: auto;
}
.u-pl-0 {
  padding-left: 0;
}
.u-pl-4 {
  padding-left: 0.4rem;
}
.u-pl-8 {
  padding-left: 0.8rem;
}
.u-pl-16 {
  padding-left: 1.6rem;
}
.u-pl-24 {
  padding-left: 2.4rem;
}
.u-pl-32 {
  padding-left: 3.2rem;
}
.u-pl-40 {
  padding-left: 4rem;
}
.u-pl-48 {
  padding-left: 4.8rem;
}
.u-pl-56 {
  padding-left: 5.6rem;
}
.u-pl-64 {
  padding-left: 6.4rem;
}
.u-pl-72 {
  padding-left: 7.2rem;
}
.u-pl-80 {
  padding-left: 8rem;
}
.u-pl-88 {
  padding-left: 8.8rem;
}
.u-pl-96 {
  padding-left: 9.6rem;
}

.u-pr-auto {
  padding-right: auto;
}
.u-pr-0 {
  padding-right: 0;
}
.u-pr-4 {
  padding-right: 0.4rem;
}
.u-pr-8 {
  padding-right: 0.8rem;
}
.u-pr-16 {
  padding-right: 1.6rem;
}
.u-pr-24 {
  padding-right: 2.4rem;
}
.u-pr-32 {
  padding-right: 3.2rem;
}
.u-pr-40 {
  padding-right: 4rem;
}
.u-pr-48 {
  padding-right: 4.8rem;
}
.u-pr-56 {
  padding-right: 5.6rem;
}
.u-pr-64 {
  padding-right: 6.4rem;
}
.u-pr-72 {
  padding-right: 7.2rem;
}
.u-pr-80 {
  padding-right: 8rem;
}
.u-pr-88 {
  padding-right: 8.8rem;
}
.u-pr-96 {
  padding-right: 9.6rem;
}

.u-py-auto {
  padding-top: auto;
  padding-bottom: auto;
}
.u-py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.u-py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.u-py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
.u-py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}
.u-py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}
.u-py-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
}
.u-py-40 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.u-py-48 {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
}
.u-py-56 {
  padding-top: 5.6rem;
  padding-bottom: 5.6rem;
}
.u-py-64 {
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;
}
.u-py-72 {
  padding-top: 7.2rem;
  padding-bottom: 7.2rem;
}
.u-py-80 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.u-py-88 {
  padding-top: 8.8rem;
  padding-bottom: 8.8rem;
}
.u-py-96 {
  padding-top: 9.6rem;
  padding-bottom: 9.6rem;
}

.u-pt-auto {
  padding-top: auto;
}
.u-pt-0 {
  padding-top: 0;
}
.u-pt-4 {
  padding-top: 0.4rem;
}
.u-pt-8 {
  padding-top: 0.8rem;
}
.u-pt-16 {
  padding-top: 1.6rem;
}
.u-pt-24 {
  padding-top: 2.4rem;
}
.u-pt-32 {
  padding-top: 3.2rem;
}
.u-pt-40 {
  padding-top: 4rem;
}
.u-pt-48 {
  padding-top: 4.8rem;
}
.u-pt-56 {
  padding-top: 5.6rem;
}
.u-pt-64 {
  padding-top: 6.4rem;
}
.u-pt-72 {
  padding-top: 7.2rem;
}
.u-pt-80 {
  padding-top: 8rem;
}
.u-pt-88 {
  padding-top: 8.8rem;
}
.u-pt-96 {
  padding-top: 9.6rem;
}

.u-pb-auto {
  padding-bottom: auto;
}
.u-pb-0 {
  padding-bottom: 0;
}
.u-pb-4 {
  padding-bottom: 0.4rem;
}
.u-pb-8 {
  padding-bottom: 0.8rem;
}
.u-pb-16 {
  padding-bottom: 1.6rem;
}
.u-pb-24 {
  padding-bottom: 2.4rem;
}
.u-pb-32 {
  padding-bottom: 3.2rem;
}
.u-pb-40 {
  padding-bottom: 4rem;
}
.u-pb-48 {
  padding-bottom: 4.8rem;
}
.u-pb-56 {
  padding-bottom: 5.6rem;
}
.u-pb-64 {
  padding-bottom: 6.4rem;
}
.u-pb-72 {
  padding-bottom: 7.2rem;
}
.u-pb-80 {
  padding-bottom: 8rem;
}
.u-pb-88 {
  padding-bottom: 8.8rem;
}
.u-pb-96 {
  padding-bottom: 9.6rem;
}

@media only screen and (min-width: 480px) {
  .u-p-auto--xs {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .u-p-0--xs {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .u-p-4--xs {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  .u-p-8--xs {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-p-16--xs {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-p-24--xs {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-p-32--xs {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-p-40--xs {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-p-48--xs {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-p-56--xs {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-p-64--xs {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-p-72--xs {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-p-80--xs {
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-p-88--xs {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-p-96--xs {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-px-auto--xs {
    padding-left: auto;
    padding-right: auto;
  }
  .u-px-0--xs {
    padding-left: 0;
    padding-right: 0;
  }
  .u-px-4--xs {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  .u-px-8--xs {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-px-16--xs {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-px-24--xs {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-px-32--xs {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-px-40--xs {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-px-48--xs {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-px-56--xs {
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-px-64--xs {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-px-72--xs {
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-px-80--xs {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-px-88--xs {
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-px-96--xs {
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-pl-auto--xs {
    padding-left: auto;
  }
  .u-pl-0--xs {
    padding-left: 0;
  }
  .u-pl-4--xs {
    padding-left: 0.4rem;
  }
  .u-pl-8--xs {
    padding-left: 0.8rem;
  }
  .u-pl-16--xs {
    padding-left: 1.6rem;
  }
  .u-pl-24--xs {
    padding-left: 2.4rem;
  }
  .u-pl-32--xs {
    padding-left: 3.2rem;
  }
  .u-pl-40--xs {
    padding-left: 4rem;
  }
  .u-pl-48--xs {
    padding-left: 4.8rem;
  }
  .u-pl-56--xs {
    padding-left: 5.6rem;
  }
  .u-pl-64--xs {
    padding-left: 6.4rem;
  }
  .u-pl-72--xs {
    padding-left: 7.2rem;
  }
  .u-pl-80--xs {
    padding-left: 8rem;
  }
  .u-pl-88--xs {
    padding-left: 8.8rem;
  }
  .u-pl-96--xs {
    padding-left: 9.6rem;
  }
  .u-pr-auto--xs {
    padding-right: auto;
  }
  .u-pr-0--xs {
    padding-right: 0;
  }
  .u-pr-4--xs {
    padding-right: 0.4rem;
  }
  .u-pr-8--xs {
    padding-right: 0.8rem;
  }
  .u-pr-16--xs {
    padding-right: 1.6rem;
  }
  .u-pr-24--xs {
    padding-right: 2.4rem;
  }
  .u-pr-32--xs {
    padding-right: 3.2rem;
  }
  .u-pr-40--xs {
    padding-right: 4rem;
  }
  .u-pr-48--xs {
    padding-right: 4.8rem;
  }
  .u-pr-56--xs {
    padding-right: 5.6rem;
  }
  .u-pr-64--xs {
    padding-right: 6.4rem;
  }
  .u-pr-72--xs {
    padding-right: 7.2rem;
  }
  .u-pr-80--xs {
    padding-right: 8rem;
  }
  .u-pr-88--xs {
    padding-right: 8.8rem;
  }
  .u-pr-96--xs {
    padding-right: 9.6rem;
  }
  .u-py-auto--xs {
    padding-top: auto;
    padding-bottom: auto;
  }
  .u-py-0--xs {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-py-4--xs {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
  .u-py-8--xs {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .u-py-16--xs {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .u-py-24--xs {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .u-py-32--xs {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
  .u-py-40--xs {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .u-py-48--xs {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
  .u-py-56--xs {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
  }
  .u-py-64--xs {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
  .u-py-72--xs {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
  }
  .u-py-80--xs {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .u-py-88--xs {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
  }
  .u-py-96--xs {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
  }
  .u-pt-auto--xs {
    padding-top: auto;
  }
  .u-pt-0--xs {
    padding-top: 0;
  }
  .u-pt-4--xs {
    padding-top: 0.4rem;
  }
  .u-pt-8--xs {
    padding-top: 0.8rem;
  }
  .u-pt-16--xs {
    padding-top: 1.6rem;
  }
  .u-pt-24--xs {
    padding-top: 2.4rem;
  }
  .u-pt-32--xs {
    padding-top: 3.2rem;
  }
  .u-pt-40--xs {
    padding-top: 4rem;
  }
  .u-pt-48--xs {
    padding-top: 4.8rem;
  }
  .u-pt-56--xs {
    padding-top: 5.6rem;
  }
  .u-pt-64--xs {
    padding-top: 6.4rem;
  }
  .u-pt-72--xs {
    padding-top: 7.2rem;
  }
  .u-pt-80--xs {
    padding-top: 8rem;
  }
  .u-pt-88--xs {
    padding-top: 8.8rem;
  }
  .u-pt-96--xs {
    padding-top: 9.6rem;
  }
  .u-pb-auto--xs {
    padding-bottom: auto;
  }
  .u-pb-0--xs {
    padding-bottom: 0;
  }
  .u-pb-4--xs {
    padding-bottom: 0.4rem;
  }
  .u-pb-8--xs {
    padding-bottom: 0.8rem;
  }
  .u-pb-16--xs {
    padding-bottom: 1.6rem;
  }
  .u-pb-24--xs {
    padding-bottom: 2.4rem;
  }
  .u-pb-32--xs {
    padding-bottom: 3.2rem;
  }
  .u-pb-40--xs {
    padding-bottom: 4rem;
  }
  .u-pb-48--xs {
    padding-bottom: 4.8rem;
  }
  .u-pb-56--xs {
    padding-bottom: 5.6rem;
  }
  .u-pb-64--xs {
    padding-bottom: 6.4rem;
  }
  .u-pb-72--xs {
    padding-bottom: 7.2rem;
  }
  .u-pb-80--xs {
    padding-bottom: 8rem;
  }
  .u-pb-88--xs {
    padding-bottom: 8.8rem;
  }
  .u-pb-96--xs {
    padding-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 640px) {
  .u-p-auto--sm {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .u-p-0--sm {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .u-p-4--sm {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  .u-p-8--sm {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-p-16--sm {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-p-24--sm {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-p-32--sm {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-p-40--sm {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-p-48--sm {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-p-56--sm {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-p-64--sm {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-p-72--sm {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-p-80--sm {
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-p-88--sm {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-p-96--sm {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-px-auto--sm {
    padding-left: auto;
    padding-right: auto;
  }
  .u-px-0--sm {
    padding-left: 0;
    padding-right: 0;
  }
  .u-px-4--sm {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  .u-px-8--sm {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-px-16--sm {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-px-24--sm {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-px-32--sm {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-px-40--sm {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-px-48--sm {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-px-56--sm {
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-px-64--sm {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-px-72--sm {
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-px-80--sm {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-px-88--sm {
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-px-96--sm {
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-pl-auto--sm {
    padding-left: auto;
  }
  .u-pl-0--sm {
    padding-left: 0;
  }
  .u-pl-4--sm {
    padding-left: 0.4rem;
  }
  .u-pl-8--sm {
    padding-left: 0.8rem;
  }
  .u-pl-16--sm {
    padding-left: 1.6rem;
  }
  .u-pl-24--sm {
    padding-left: 2.4rem;
  }
  .u-pl-32--sm {
    padding-left: 3.2rem;
  }
  .u-pl-40--sm {
    padding-left: 4rem;
  }
  .u-pl-48--sm {
    padding-left: 4.8rem;
  }
  .u-pl-56--sm {
    padding-left: 5.6rem;
  }
  .u-pl-64--sm {
    padding-left: 6.4rem;
  }
  .u-pl-72--sm {
    padding-left: 7.2rem;
  }
  .u-pl-80--sm {
    padding-left: 8rem;
  }
  .u-pl-88--sm {
    padding-left: 8.8rem;
  }
  .u-pl-96--sm {
    padding-left: 9.6rem;
  }
  .u-pr-auto--sm {
    padding-right: auto;
  }
  .u-pr-0--sm {
    padding-right: 0;
  }
  .u-pr-4--sm {
    padding-right: 0.4rem;
  }
  .u-pr-8--sm {
    padding-right: 0.8rem;
  }
  .u-pr-16--sm {
    padding-right: 1.6rem;
  }
  .u-pr-24--sm {
    padding-right: 2.4rem;
  }
  .u-pr-32--sm {
    padding-right: 3.2rem;
  }
  .u-pr-40--sm {
    padding-right: 4rem;
  }
  .u-pr-48--sm {
    padding-right: 4.8rem;
  }
  .u-pr-56--sm {
    padding-right: 5.6rem;
  }
  .u-pr-64--sm {
    padding-right: 6.4rem;
  }
  .u-pr-72--sm {
    padding-right: 7.2rem;
  }
  .u-pr-80--sm {
    padding-right: 8rem;
  }
  .u-pr-88--sm {
    padding-right: 8.8rem;
  }
  .u-pr-96--sm {
    padding-right: 9.6rem;
  }
  .u-py-auto--sm {
    padding-top: auto;
    padding-bottom: auto;
  }
  .u-py-0--sm {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-py-4--sm {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
  .u-py-8--sm {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .u-py-16--sm {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .u-py-24--sm {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .u-py-32--sm {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
  .u-py-40--sm {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .u-py-48--sm {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
  .u-py-56--sm {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
  }
  .u-py-64--sm {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
  .u-py-72--sm {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
  }
  .u-py-80--sm {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .u-py-88--sm {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
  }
  .u-py-96--sm {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
  }
  .u-pt-auto--sm {
    padding-top: auto;
  }
  .u-pt-0--sm {
    padding-top: 0;
  }
  .u-pt-4--sm {
    padding-top: 0.4rem;
  }
  .u-pt-8--sm {
    padding-top: 0.8rem;
  }
  .u-pt-16--sm {
    padding-top: 1.6rem;
  }
  .u-pt-24--sm {
    padding-top: 2.4rem;
  }
  .u-pt-32--sm {
    padding-top: 3.2rem;
  }
  .u-pt-40--sm {
    padding-top: 4rem;
  }
  .u-pt-48--sm {
    padding-top: 4.8rem;
  }
  .u-pt-56--sm {
    padding-top: 5.6rem;
  }
  .u-pt-64--sm {
    padding-top: 6.4rem;
  }
  .u-pt-72--sm {
    padding-top: 7.2rem;
  }
  .u-pt-80--sm {
    padding-top: 8rem;
  }
  .u-pt-88--sm {
    padding-top: 8.8rem;
  }
  .u-pt-96--sm {
    padding-top: 9.6rem;
  }
  .u-pb-auto--sm {
    padding-bottom: auto;
  }
  .u-pb-0--sm {
    padding-bottom: 0;
  }
  .u-pb-4--sm {
    padding-bottom: 0.4rem;
  }
  .u-pb-8--sm {
    padding-bottom: 0.8rem;
  }
  .u-pb-16--sm {
    padding-bottom: 1.6rem;
  }
  .u-pb-24--sm {
    padding-bottom: 2.4rem;
  }
  .u-pb-32--sm {
    padding-bottom: 3.2rem;
  }
  .u-pb-40--sm {
    padding-bottom: 4rem;
  }
  .u-pb-48--sm {
    padding-bottom: 4.8rem;
  }
  .u-pb-56--sm {
    padding-bottom: 5.6rem;
  }
  .u-pb-64--sm {
    padding-bottom: 6.4rem;
  }
  .u-pb-72--sm {
    padding-bottom: 7.2rem;
  }
  .u-pb-80--sm {
    padding-bottom: 8rem;
  }
  .u-pb-88--sm {
    padding-bottom: 8.8rem;
  }
  .u-pb-96--sm {
    padding-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 768px) {
  .u-p-auto--md {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .u-p-0--md {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .u-p-4--md {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  .u-p-8--md {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-p-16--md {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-p-24--md {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-p-32--md {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-p-40--md {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-p-48--md {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-p-56--md {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-p-64--md {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-p-72--md {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-p-80--md {
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-p-88--md {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-p-96--md {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-px-auto--md {
    padding-left: auto;
    padding-right: auto;
  }
  .u-px-0--md {
    padding-left: 0;
    padding-right: 0;
  }
  .u-px-4--md {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  .u-px-8--md {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-px-16--md {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-px-24--md {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-px-32--md {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-px-40--md {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-px-48--md {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-px-56--md {
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-px-64--md {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-px-72--md {
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-px-80--md {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-px-88--md {
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-px-96--md {
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-pl-auto--md {
    padding-left: auto;
  }
  .u-pl-0--md {
    padding-left: 0;
  }
  .u-pl-4--md {
    padding-left: 0.4rem;
  }
  .u-pl-8--md {
    padding-left: 0.8rem;
  }
  .u-pl-16--md {
    padding-left: 1.6rem;
  }
  .u-pl-24--md {
    padding-left: 2.4rem;
  }
  .u-pl-32--md {
    padding-left: 3.2rem;
  }
  .u-pl-40--md {
    padding-left: 4rem;
  }
  .u-pl-48--md {
    padding-left: 4.8rem;
  }
  .u-pl-56--md {
    padding-left: 5.6rem;
  }
  .u-pl-64--md {
    padding-left: 6.4rem;
  }
  .u-pl-72--md {
    padding-left: 7.2rem;
  }
  .u-pl-80--md {
    padding-left: 8rem;
  }
  .u-pl-88--md {
    padding-left: 8.8rem;
  }
  .u-pl-96--md {
    padding-left: 9.6rem;
  }
  .u-pr-auto--md {
    padding-right: auto;
  }
  .u-pr-0--md {
    padding-right: 0;
  }
  .u-pr-4--md {
    padding-right: 0.4rem;
  }
  .u-pr-8--md {
    padding-right: 0.8rem;
  }
  .u-pr-16--md {
    padding-right: 1.6rem;
  }
  .u-pr-24--md {
    padding-right: 2.4rem;
  }
  .u-pr-32--md {
    padding-right: 3.2rem;
  }
  .u-pr-40--md {
    padding-right: 4rem;
  }
  .u-pr-48--md {
    padding-right: 4.8rem;
  }
  .u-pr-56--md {
    padding-right: 5.6rem;
  }
  .u-pr-64--md {
    padding-right: 6.4rem;
  }
  .u-pr-72--md {
    padding-right: 7.2rem;
  }
  .u-pr-80--md {
    padding-right: 8rem;
  }
  .u-pr-88--md {
    padding-right: 8.8rem;
  }
  .u-pr-96--md {
    padding-right: 9.6rem;
  }
  .u-py-auto--md {
    padding-top: auto;
    padding-bottom: auto;
  }
  .u-py-0--md {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-py-4--md {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
  .u-py-8--md {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .u-py-16--md {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .u-py-24--md {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .u-py-32--md {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
  .u-py-40--md {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .u-py-48--md {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
  .u-py-56--md {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
  }
  .u-py-64--md {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
  .u-py-72--md {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
  }
  .u-py-80--md {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .u-py-88--md {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
  }
  .u-py-96--md {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
  }
  .u-pt-auto--md {
    padding-top: auto;
  }
  .u-pt-0--md {
    padding-top: 0;
  }
  .u-pt-4--md {
    padding-top: 0.4rem;
  }
  .u-pt-8--md {
    padding-top: 0.8rem;
  }
  .u-pt-16--md {
    padding-top: 1.6rem;
  }
  .u-pt-24--md {
    padding-top: 2.4rem;
  }
  .u-pt-32--md {
    padding-top: 3.2rem;
  }
  .u-pt-40--md {
    padding-top: 4rem;
  }
  .u-pt-48--md {
    padding-top: 4.8rem;
  }
  .u-pt-56--md {
    padding-top: 5.6rem;
  }
  .u-pt-64--md {
    padding-top: 6.4rem;
  }
  .u-pt-72--md {
    padding-top: 7.2rem;
  }
  .u-pt-80--md {
    padding-top: 8rem;
  }
  .u-pt-88--md {
    padding-top: 8.8rem;
  }
  .u-pt-96--md {
    padding-top: 9.6rem;
  }
  .u-pb-auto--md {
    padding-bottom: auto;
  }
  .u-pb-0--md {
    padding-bottom: 0;
  }
  .u-pb-4--md {
    padding-bottom: 0.4rem;
  }
  .u-pb-8--md {
    padding-bottom: 0.8rem;
  }
  .u-pb-16--md {
    padding-bottom: 1.6rem;
  }
  .u-pb-24--md {
    padding-bottom: 2.4rem;
  }
  .u-pb-32--md {
    padding-bottom: 3.2rem;
  }
  .u-pb-40--md {
    padding-bottom: 4rem;
  }
  .u-pb-48--md {
    padding-bottom: 4.8rem;
  }
  .u-pb-56--md {
    padding-bottom: 5.6rem;
  }
  .u-pb-64--md {
    padding-bottom: 6.4rem;
  }
  .u-pb-72--md {
    padding-bottom: 7.2rem;
  }
  .u-pb-80--md {
    padding-bottom: 8rem;
  }
  .u-pb-88--md {
    padding-bottom: 8.8rem;
  }
  .u-pb-96--md {
    padding-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .u-p-auto--lg {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .u-p-0--lg {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .u-p-4--lg {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  .u-p-8--lg {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-p-16--lg {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-p-24--lg {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-p-32--lg {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-p-40--lg {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-p-48--lg {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-p-56--lg {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-p-64--lg {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-p-72--lg {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-p-80--lg {
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-p-88--lg {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-p-96--lg {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-px-auto--lg {
    padding-left: auto;
    padding-right: auto;
  }
  .u-px-0--lg {
    padding-left: 0;
    padding-right: 0;
  }
  .u-px-4--lg {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  .u-px-8--lg {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-px-16--lg {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-px-24--lg {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-px-32--lg {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-px-40--lg {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-px-48--lg {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-px-56--lg {
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-px-64--lg {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-px-72--lg {
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-px-80--lg {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-px-88--lg {
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-px-96--lg {
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-pl-auto--lg {
    padding-left: auto;
  }
  .u-pl-0--lg {
    padding-left: 0;
  }
  .u-pl-4--lg {
    padding-left: 0.4rem;
  }
  .u-pl-8--lg {
    padding-left: 0.8rem;
  }
  .u-pl-16--lg {
    padding-left: 1.6rem;
  }
  .u-pl-24--lg {
    padding-left: 2.4rem;
  }
  .u-pl-32--lg {
    padding-left: 3.2rem;
  }
  .u-pl-40--lg {
    padding-left: 4rem;
  }
  .u-pl-48--lg {
    padding-left: 4.8rem;
  }
  .u-pl-56--lg {
    padding-left: 5.6rem;
  }
  .u-pl-64--lg {
    padding-left: 6.4rem;
  }
  .u-pl-72--lg {
    padding-left: 7.2rem;
  }
  .u-pl-80--lg {
    padding-left: 8rem;
  }
  .u-pl-88--lg {
    padding-left: 8.8rem;
  }
  .u-pl-96--lg {
    padding-left: 9.6rem;
  }
  .u-pr-auto--lg {
    padding-right: auto;
  }
  .u-pr-0--lg {
    padding-right: 0;
  }
  .u-pr-4--lg {
    padding-right: 0.4rem;
  }
  .u-pr-8--lg {
    padding-right: 0.8rem;
  }
  .u-pr-16--lg {
    padding-right: 1.6rem;
  }
  .u-pr-24--lg {
    padding-right: 2.4rem;
  }
  .u-pr-32--lg {
    padding-right: 3.2rem;
  }
  .u-pr-40--lg {
    padding-right: 4rem;
  }
  .u-pr-48--lg {
    padding-right: 4.8rem;
  }
  .u-pr-56--lg {
    padding-right: 5.6rem;
  }
  .u-pr-64--lg {
    padding-right: 6.4rem;
  }
  .u-pr-72--lg {
    padding-right: 7.2rem;
  }
  .u-pr-80--lg {
    padding-right: 8rem;
  }
  .u-pr-88--lg {
    padding-right: 8.8rem;
  }
  .u-pr-96--lg {
    padding-right: 9.6rem;
  }
  .u-py-auto--lg {
    padding-top: auto;
    padding-bottom: auto;
  }
  .u-py-0--lg {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-py-4--lg {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
  .u-py-8--lg {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .u-py-16--lg {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .u-py-24--lg {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .u-py-32--lg {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
  .u-py-40--lg {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .u-py-48--lg {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
  .u-py-56--lg {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
  }
  .u-py-64--lg {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
  .u-py-72--lg {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
  }
  .u-py-80--lg {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .u-py-88--lg {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
  }
  .u-py-96--lg {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
  }
  .u-pt-auto--lg {
    padding-top: auto;
  }
  .u-pt-0--lg {
    padding-top: 0;
  }
  .u-pt-4--lg {
    padding-top: 0.4rem;
  }
  .u-pt-8--lg {
    padding-top: 0.8rem;
  }
  .u-pt-16--lg {
    padding-top: 1.6rem;
  }
  .u-pt-24--lg {
    padding-top: 2.4rem;
  }
  .u-pt-32--lg {
    padding-top: 3.2rem;
  }
  .u-pt-40--lg {
    padding-top: 4rem;
  }
  .u-pt-48--lg {
    padding-top: 4.8rem;
  }
  .u-pt-56--lg {
    padding-top: 5.6rem;
  }
  .u-pt-64--lg {
    padding-top: 6.4rem;
  }
  .u-pt-72--lg {
    padding-top: 7.2rem;
  }
  .u-pt-80--lg {
    padding-top: 8rem;
  }
  .u-pt-88--lg {
    padding-top: 8.8rem;
  }
  .u-pt-96--lg {
    padding-top: 9.6rem;
  }
  .u-pb-auto--lg {
    padding-bottom: auto;
  }
  .u-pb-0--lg {
    padding-bottom: 0;
  }
  .u-pb-4--lg {
    padding-bottom: 0.4rem;
  }
  .u-pb-8--lg {
    padding-bottom: 0.8rem;
  }
  .u-pb-16--lg {
    padding-bottom: 1.6rem;
  }
  .u-pb-24--lg {
    padding-bottom: 2.4rem;
  }
  .u-pb-32--lg {
    padding-bottom: 3.2rem;
  }
  .u-pb-40--lg {
    padding-bottom: 4rem;
  }
  .u-pb-48--lg {
    padding-bottom: 4.8rem;
  }
  .u-pb-56--lg {
    padding-bottom: 5.6rem;
  }
  .u-pb-64--lg {
    padding-bottom: 6.4rem;
  }
  .u-pb-72--lg {
    padding-bottom: 7.2rem;
  }
  .u-pb-80--lg {
    padding-bottom: 8rem;
  }
  .u-pb-88--lg {
    padding-bottom: 8.8rem;
  }
  .u-pb-96--lg {
    padding-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 1280px) {
  .u-p-auto--xl {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .u-p-0--xl {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .u-p-4--xl {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  .u-p-8--xl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-p-16--xl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-p-24--xl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-p-32--xl {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-p-40--xl {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-p-48--xl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-p-56--xl {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-p-64--xl {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-p-72--xl {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-p-80--xl {
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-p-88--xl {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-p-96--xl {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-px-auto--xl {
    padding-left: auto;
    padding-right: auto;
  }
  .u-px-0--xl {
    padding-left: 0;
    padding-right: 0;
  }
  .u-px-4--xl {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  .u-px-8--xl {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-px-16--xl {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-px-24--xl {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-px-32--xl {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-px-40--xl {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-px-48--xl {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-px-56--xl {
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-px-64--xl {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-px-72--xl {
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-px-80--xl {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-px-88--xl {
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-px-96--xl {
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-pl-auto--xl {
    padding-left: auto;
  }
  .u-pl-0--xl {
    padding-left: 0;
  }
  .u-pl-4--xl {
    padding-left: 0.4rem;
  }
  .u-pl-8--xl {
    padding-left: 0.8rem;
  }
  .u-pl-16--xl {
    padding-left: 1.6rem;
  }
  .u-pl-24--xl {
    padding-left: 2.4rem;
  }
  .u-pl-32--xl {
    padding-left: 3.2rem;
  }
  .u-pl-40--xl {
    padding-left: 4rem;
  }
  .u-pl-48--xl {
    padding-left: 4.8rem;
  }
  .u-pl-56--xl {
    padding-left: 5.6rem;
  }
  .u-pl-64--xl {
    padding-left: 6.4rem;
  }
  .u-pl-72--xl {
    padding-left: 7.2rem;
  }
  .u-pl-80--xl {
    padding-left: 8rem;
  }
  .u-pl-88--xl {
    padding-left: 8.8rem;
  }
  .u-pl-96--xl {
    padding-left: 9.6rem;
  }
  .u-pr-auto--xl {
    padding-right: auto;
  }
  .u-pr-0--xl {
    padding-right: 0;
  }
  .u-pr-4--xl {
    padding-right: 0.4rem;
  }
  .u-pr-8--xl {
    padding-right: 0.8rem;
  }
  .u-pr-16--xl {
    padding-right: 1.6rem;
  }
  .u-pr-24--xl {
    padding-right: 2.4rem;
  }
  .u-pr-32--xl {
    padding-right: 3.2rem;
  }
  .u-pr-40--xl {
    padding-right: 4rem;
  }
  .u-pr-48--xl {
    padding-right: 4.8rem;
  }
  .u-pr-56--xl {
    padding-right: 5.6rem;
  }
  .u-pr-64--xl {
    padding-right: 6.4rem;
  }
  .u-pr-72--xl {
    padding-right: 7.2rem;
  }
  .u-pr-80--xl {
    padding-right: 8rem;
  }
  .u-pr-88--xl {
    padding-right: 8.8rem;
  }
  .u-pr-96--xl {
    padding-right: 9.6rem;
  }
  .u-py-auto--xl {
    padding-top: auto;
    padding-bottom: auto;
  }
  .u-py-0--xl {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-py-4--xl {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
  .u-py-8--xl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .u-py-16--xl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .u-py-24--xl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .u-py-32--xl {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
  .u-py-40--xl {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .u-py-48--xl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
  .u-py-56--xl {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
  }
  .u-py-64--xl {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
  .u-py-72--xl {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
  }
  .u-py-80--xl {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .u-py-88--xl {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
  }
  .u-py-96--xl {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
  }
  .u-pt-auto--xl {
    padding-top: auto;
  }
  .u-pt-0--xl {
    padding-top: 0;
  }
  .u-pt-4--xl {
    padding-top: 0.4rem;
  }
  .u-pt-8--xl {
    padding-top: 0.8rem;
  }
  .u-pt-16--xl {
    padding-top: 1.6rem;
  }
  .u-pt-24--xl {
    padding-top: 2.4rem;
  }
  .u-pt-32--xl {
    padding-top: 3.2rem;
  }
  .u-pt-40--xl {
    padding-top: 4rem;
  }
  .u-pt-48--xl {
    padding-top: 4.8rem;
  }
  .u-pt-56--xl {
    padding-top: 5.6rem;
  }
  .u-pt-64--xl {
    padding-top: 6.4rem;
  }
  .u-pt-72--xl {
    padding-top: 7.2rem;
  }
  .u-pt-80--xl {
    padding-top: 8rem;
  }
  .u-pt-88--xl {
    padding-top: 8.8rem;
  }
  .u-pt-96--xl {
    padding-top: 9.6rem;
  }
  .u-pb-auto--xl {
    padding-bottom: auto;
  }
  .u-pb-0--xl {
    padding-bottom: 0;
  }
  .u-pb-4--xl {
    padding-bottom: 0.4rem;
  }
  .u-pb-8--xl {
    padding-bottom: 0.8rem;
  }
  .u-pb-16--xl {
    padding-bottom: 1.6rem;
  }
  .u-pb-24--xl {
    padding-bottom: 2.4rem;
  }
  .u-pb-32--xl {
    padding-bottom: 3.2rem;
  }
  .u-pb-40--xl {
    padding-bottom: 4rem;
  }
  .u-pb-48--xl {
    padding-bottom: 4.8rem;
  }
  .u-pb-56--xl {
    padding-bottom: 5.6rem;
  }
  .u-pb-64--xl {
    padding-bottom: 6.4rem;
  }
  .u-pb-72--xl {
    padding-bottom: 7.2rem;
  }
  .u-pb-80--xl {
    padding-bottom: 8rem;
  }
  .u-pb-88--xl {
    padding-bottom: 8.8rem;
  }
  .u-pb-96--xl {
    padding-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 1920px) {
  .u-p-auto--xxl {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .u-p-0--xxl {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .u-p-4--xxl {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  .u-p-8--xxl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-p-16--xxl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-p-24--xxl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-p-32--xxl {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-p-40--xxl {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-p-48--xxl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-p-56--xxl {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-p-64--xxl {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-p-72--xxl {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-p-80--xxl {
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-p-88--xxl {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-p-96--xxl {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-px-auto--xxl {
    padding-left: auto;
    padding-right: auto;
  }
  .u-px-0--xxl {
    padding-left: 0;
    padding-right: 0;
  }
  .u-px-4--xxl {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  .u-px-8--xxl {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-px-16--xxl {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-px-24--xxl {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-px-32--xxl {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-px-40--xxl {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-px-48--xxl {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-px-56--xxl {
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-px-64--xxl {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-px-72--xxl {
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-px-80--xxl {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-px-88--xxl {
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-px-96--xxl {
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-pl-auto--xxl {
    padding-left: auto;
  }
  .u-pl-0--xxl {
    padding-left: 0;
  }
  .u-pl-4--xxl {
    padding-left: 0.4rem;
  }
  .u-pl-8--xxl {
    padding-left: 0.8rem;
  }
  .u-pl-16--xxl {
    padding-left: 1.6rem;
  }
  .u-pl-24--xxl {
    padding-left: 2.4rem;
  }
  .u-pl-32--xxl {
    padding-left: 3.2rem;
  }
  .u-pl-40--xxl {
    padding-left: 4rem;
  }
  .u-pl-48--xxl {
    padding-left: 4.8rem;
  }
  .u-pl-56--xxl {
    padding-left: 5.6rem;
  }
  .u-pl-64--xxl {
    padding-left: 6.4rem;
  }
  .u-pl-72--xxl {
    padding-left: 7.2rem;
  }
  .u-pl-80--xxl {
    padding-left: 8rem;
  }
  .u-pl-88--xxl {
    padding-left: 8.8rem;
  }
  .u-pl-96--xxl {
    padding-left: 9.6rem;
  }
  .u-pr-auto--xxl {
    padding-right: auto;
  }
  .u-pr-0--xxl {
    padding-right: 0;
  }
  .u-pr-4--xxl {
    padding-right: 0.4rem;
  }
  .u-pr-8--xxl {
    padding-right: 0.8rem;
  }
  .u-pr-16--xxl {
    padding-right: 1.6rem;
  }
  .u-pr-24--xxl {
    padding-right: 2.4rem;
  }
  .u-pr-32--xxl {
    padding-right: 3.2rem;
  }
  .u-pr-40--xxl {
    padding-right: 4rem;
  }
  .u-pr-48--xxl {
    padding-right: 4.8rem;
  }
  .u-pr-56--xxl {
    padding-right: 5.6rem;
  }
  .u-pr-64--xxl {
    padding-right: 6.4rem;
  }
  .u-pr-72--xxl {
    padding-right: 7.2rem;
  }
  .u-pr-80--xxl {
    padding-right: 8rem;
  }
  .u-pr-88--xxl {
    padding-right: 8.8rem;
  }
  .u-pr-96--xxl {
    padding-right: 9.6rem;
  }
  .u-py-auto--xxl {
    padding-top: auto;
    padding-bottom: auto;
  }
  .u-py-0--xxl {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-py-4--xxl {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
  .u-py-8--xxl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .u-py-16--xxl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .u-py-24--xxl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .u-py-32--xxl {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
  .u-py-40--xxl {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .u-py-48--xxl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
  .u-py-56--xxl {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
  }
  .u-py-64--xxl {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
  .u-py-72--xxl {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
  }
  .u-py-80--xxl {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .u-py-88--xxl {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
  }
  .u-py-96--xxl {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
  }
  .u-pt-auto--xxl {
    padding-top: auto;
  }
  .u-pt-0--xxl {
    padding-top: 0;
  }
  .u-pt-4--xxl {
    padding-top: 0.4rem;
  }
  .u-pt-8--xxl {
    padding-top: 0.8rem;
  }
  .u-pt-16--xxl {
    padding-top: 1.6rem;
  }
  .u-pt-24--xxl {
    padding-top: 2.4rem;
  }
  .u-pt-32--xxl {
    padding-top: 3.2rem;
  }
  .u-pt-40--xxl {
    padding-top: 4rem;
  }
  .u-pt-48--xxl {
    padding-top: 4.8rem;
  }
  .u-pt-56--xxl {
    padding-top: 5.6rem;
  }
  .u-pt-64--xxl {
    padding-top: 6.4rem;
  }
  .u-pt-72--xxl {
    padding-top: 7.2rem;
  }
  .u-pt-80--xxl {
    padding-top: 8rem;
  }
  .u-pt-88--xxl {
    padding-top: 8.8rem;
  }
  .u-pt-96--xxl {
    padding-top: 9.6rem;
  }
  .u-pb-auto--xxl {
    padding-bottom: auto;
  }
  .u-pb-0--xxl {
    padding-bottom: 0;
  }
  .u-pb-4--xxl {
    padding-bottom: 0.4rem;
  }
  .u-pb-8--xxl {
    padding-bottom: 0.8rem;
  }
  .u-pb-16--xxl {
    padding-bottom: 1.6rem;
  }
  .u-pb-24--xxl {
    padding-bottom: 2.4rem;
  }
  .u-pb-32--xxl {
    padding-bottom: 3.2rem;
  }
  .u-pb-40--xxl {
    padding-bottom: 4rem;
  }
  .u-pb-48--xxl {
    padding-bottom: 4.8rem;
  }
  .u-pb-56--xxl {
    padding-bottom: 5.6rem;
  }
  .u-pb-64--xxl {
    padding-bottom: 6.4rem;
  }
  .u-pb-72--xxl {
    padding-bottom: 7.2rem;
  }
  .u-pb-80--xxl {
    padding-bottom: 8rem;
  }
  .u-pb-88--xxl {
    padding-bottom: 8.8rem;
  }
  .u-pb-96--xxl {
    padding-bottom: 9.6rem;
  }
}
:root {
  --cc-bg: #fff;
  --cc-primary-color: #020307;
  --cc-secondary-color: #020307;
  --cc-btn-primary-bg: #020307;
  --cc-btn-primary-color: #fff;
  --cc-btn-primary-border-color: var(--cc-btn-primary-bg);
  --cc-btn-primary-hover-bg: black;
  --cc-btn-primary-hover-color: #fff;
  --cc-btn-primary-hover-border-color: var(--cc-btn-primary-hover-bg);
  --cc-btn-secondary-bg: #dab9a5;
  --cc-btn-secondary-color: #020307;
  --cc-btn-secondary-border-color: var(--cc-btn-secondary-bg);
  --cc-btn-secondary-hover-bg: #cea388;
  --cc-btn-secondary-hover-color: #020307;
  --cc-btn-secondary-hover-border-color: var(--cc-btn-secondary-hover-bg);
  --cc-separator-border-color: #f9f1ef;
  --cc-toggle-on-bg: #21a97b;
  --cc-toggle-off-bg: #a2afbb;
  --cc-toggle-on-knob-bg: #fff;
  --cc-toggle-off-knob-bg: #fff;
  --cc-toggle-enabled-icon-color: var(--cc-bg);
  --cc-toggle-disabled-icon-color: var(--cc-bg);
  --cc-toggle-readonly-bg: #d0dfe6;
  --cc-toggle-readonly-knob-bg: #fff;
  --cc-toggle-readonly-knob-icon-color: #a5b2c0;
  --cc-section-category-border: var(--cc-cookie-category-block-bg);
  --cc-cookie-category-block-bg: #f0f4f7;
  --cc-cookie-category-block-border: #f0f4f7;
  --cc-cookie-category-block-hover-bg: #e9eff4;
  --cc-cookie-category-block-hover-border: #e9eff4;
  --cc-cookie-category-expanded-block-bg: transparent;
  --cc-cookie-category-expanded-block-hover-bg: #dee4e9;
  --cc-overlay-bg: rgba(0, 0, 0, .65);
  --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
  --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);
  --cc-footer-bg: var(--cc-btn-secondary-bg);
  --cc-footer-color: var(--cc-secondary-color);
  --cc-footer-border-color: #e4eaed;
}

#cc-main {
  background: transparent;
  color: var(--cc-primary-color);
  font-family: var(--cc-font-family);
  font-size: 1.5rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6em;
  position: relative;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  position: fixed;
  z-index: var(--cc-z-index);
}

#cc-main a,
#cc-main button,
#cc-main input {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 100%;
  line-height: normal;
  margin: 0;
  overflow: hidden;
}

:root {
  --cc-font-family-title: Roboto Condensed, sans-serif;
  --cc-font-family: Roboto, sans-serif;
  --cc-modal-border-radius: 0;
  --cc-btn-border-radius: 0;
  --cc-modal-transition-duration: .25s;
  --cc-link-color: var(--cc-btn-primary-bg);
  --cc-modal-margin: 1rem;
  --cc-z-index: 2147483647;
}

#cc-main a,
#cc-main b,
#cc-main em,
#cc-main strong {
  font-weight: 700;
}

#cc-main button > span {
  pointer-events: none;
}

html.disable--interaction.show--consent,
html.disable--interaction.show--consent body {
  height: auto !important;
  overflow: hidden !important;
}

@media (prefers-reduced-motion) {
  #cc-main {
    --cc-modal-transition-duration: 0s ;
  }
}
#cc-main .cm {
  background: var(--cc-bg);
  border-radius: var(--cc-modal-border-radius);
  box-shadow: 0 0.625em 1.875em rgba(0, 0, 2, 0.3);
  display: flex;
  flex-direction: column;
  width: 36rem;
  max-width: calc(100% - 2 * var(--cc-modal-margin));
  opacity: 0;
  overflow: hidden;
  position: fixed;
  transform: translateY(1.6em);
  visibility: hidden;
  z-index: 1;
}

#cc-main .cm--bottom {
  bottom: var(--cc-modal-margin);
}

#cc-main .cm--right {
  right: var(--cc-modal-margin);
}

#cc-main .cm__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  gap: 1.6rem;
  padding: 2.4rem;
}

#cc-main .cm__texts {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

#cc-main .cm__title {
  font-family: var(--cc-font-family-title);
  font-size: 2rem;
  line-height: 1.3em;
  margin-bottom: 0.65em;
}

#cc-main .cm__desc {
  color: var(--cc-secondary-color);
  max-height: 50vh;
  overflow-y: auto;
}

#cc-main .cm__btns {
  border-top: 1px solid var(--cc-separator-border-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.4rem;
}

#cc-main .cm__btn-group {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

#cc-main .cm__btn, #cc-main .pm__btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding: 1rem 1.4rem;
  min-height: 4rem;
  background: var(--cc-btn-primary-bg);
  border: 1px solid var(--cc-btn-primary-border-color);
  outline: 0;
  border-radius: var(--cc-btn-border-radius);
  font-family: var(--cc-font-family-title);
  font-size: 1.6rem;
  text-align: center;
  line-height: 1em;
  white-space: nowrap;
  color: var(--cc-btn-primary-color);
}

#cc-main .cm__btn:hover, #cc-main .pm__btn:hover {
  background: var(--cc-btn-primary-hover-bg);
  border-color: var(--cc-btn-primary-hover-border-color);
  color: var(--cc-btn-primary-hover-color);
}

#cc-main .cm__btn--secondary, #cc-main .pm__btn--secondary {
  background: var(--cc-btn-secondary-bg);
  border-color: var(--cc-btn-secondary-border-color);
  color: var(--cc-btn-secondary-color);
}

#cc-main .cm__btn--secondary:hover, #cc-main .pm__btn--secondary:hover {
  background: var(--cc-btn-secondary-hover-bg);
  border-color: var(--cc-btn-secondary-hover-border-color);
  color: var(--cc-btn-secondary-hover-color);
}

#cc-main .cm__footer {
  background: var(--cc-footer-bg);
  border-top: 1px solid var(--cc-footer-border-color);
  color: var(--cc-footer-color);
}

#cc-main .cc--anim .cm,
#cc-main .cc--anim.cm-wrapper:before {
  transition: opacity var(--cc-modal-transition-duration) ease, visibility var(--cc-modal-transition-duration) ease, transform var(--cc-modal-transition-duration) ease;
}

.disable--interaction #cc-main .cm-wrapper:before {
  background: var(--cc-overlay-bg);
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 0;
}

.show--consent #cc-main .cc--anim .cm {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.show--consent #cc-main .cc--anim .cm--middle {
  transform: translateY(-50%);
}

.show--consent #cc-main .cc--anim .cm--bar {
  transform: translateY(0);
}

.show--consent #cc-main .cc--anim.cm-wrapper:before {
  opacity: 1;
  visibility: visible;
}

:root {
  --cc-pm-toggle-border-radius: 11.5px;
}

#cc-main .pm-wrapper {
  position: relative;
  z-index: 2;
}

#cc-main .pm {
  background: var(--cc-bg);
  border-radius: var(--cc-modal-border-radius);
  box-shadow: 0 0.625em 1.875em rgba(0, 0, 2, 0.3);
  display: flex;
  flex-direction: column;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  visibility: hidden;
  width: 100%;
  z-index: 1;
}

#cc-main svg {
  fill: none;
  width: 100%;
}

#cc-main .pm__header {
  align-items: center;
  border-bottom: 1px solid var(--cc-separator-border-color);
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 0.8rem 0.8rem 2.4rem;
}

#cc-main .pm__title {
  font-family: var(--cc-font-family-title);
  font-size: 2rem;
  line-height: 1em;
  align-items: center;
  display: flex;
  flex: 1;
  margin-bottom: 0;
  margin-right: 1.6rem;
}

#cc-main .pm__close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  position: relative;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
}

#cc-main .pm__close-btn span {
  display: flex;
  height: 50%;
  width: 50%;
}

#cc-main .pm__close-btn svg {
  stroke: var(--cc-primary-color);
}

#cc-main .pm__body {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  flex: 1;
  overflow-y: auto;
  overflow-y: overlay;
  padding: 2.4rem;
}

#cc-main .pm__section,
#cc-main .pm__section--toggle {
  border-radius: var(--cc-btn-border-radius);
  display: flex;
  flex-direction: column;
}

#cc-main .pm__section--toggle .pm__section-desc-wrapper {
  border: 1px solid var(--cc-cookie-category-block-border);
  border-radius: var(--cc-btn-border-radius);
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: none;
  margin-top: 0;
  overflow: hidden;
}

#cc-main .pm__section {
  border: 1px solid var(--cc-separator-border-color);
}

#cc-main .pm__section:first-child {
  border: none;
}

#cc-main .pm__section-toggles {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

#cc-main .pm__section-toggles .pm__section-desc {
  padding: 1.6rem;
}

#cc-main .pm__section--toggle {
  background: var(--cc-cookie-category-block-bg);
}

#cc-main .pm__section--toggle .pm__section-title {
  background: var(--cc-cookie-category-block-bg);
  border: 1px solid var(--cc-cookie-category-block-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 5.6rem;
  position: relative;
  width: 100%;
}

#cc-main .pm__section--toggle.is-expanded {
  --cc-cookie-category-block-bg: transparent;
  --cc-cookie-category-block-border: var(--cc-cookie-category-expanded-block-hover-bg) ;
}

#cc-main .pm__section--toggle.is-expanded .pm__section-title {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#cc-main .pm__section--toggle.is-expanded .pm__section-arrow svg {
  transform: scale(0.5) rotate(180deg);
}

#cc-main .pm__section--toggle.is-expanded .pm__section-desc-wrapper {
  display: flex;
}

#cc-main .pm__section--expandable .pm__section-title {
  cursor: pointer;
  padding-left: 5.6rem;
}

#cc-main .pm__section--expandable .pm__section-arrow {
  background: var(--cc-toggle-readonly-bg);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  pointer-events: none;
  position: absolute;
  left: 2rem;
}

#cc-main .pm__section--expandable .pm__section-arrow svg {
  stroke: var(--cc-primary-color);
  transform: scale(0.5);
}

#cc-main .pm__section-title-wrapper {
  align-items: center;
  display: flex;
  position: relative;
}

#cc-main .section__toggle,
#cc-main .section__toggle-wrapper,
#cc-main .toggle__icon,
#cc-main .toggle__label {
  border-radius: var(--cc-pm-toggle-border-radius);
  height: 23px;
  transform: translateZ(0);
  width: 50px;
}

#cc-main .section__toggle-wrapper {
  cursor: pointer;
  position: absolute;
  right: 18px;
  z-index: 1;
}

#cc-main .toggle-service {
  height: 19px;
  position: relative;
  right: 0;
  width: 42px;
}

#cc-main .toggle-service .section__toggle,
#cc-main .toggle-service .toggle__icon,
#cc-main .toggle-service .toggle__label {
  height: 19px;
  width: 42px;
}

#cc-main .toggle-service .toggle__icon {
  position: relative;
}

#cc-main .toggle-service .toggle__icon-circle {
  height: 19px;
  width: 19px;
}

#cc-main .toggle-service .section__toggle:checked ~ .toggle__icon .toggle__icon-circle {
  transform: translateX(23px);
}

#cc-main .pm__section--toggle:nth-child(2) .section__toggle-wrapper:after {
  display: none !important;
}

#cc-main .section__toggle {
  border: 0;
  cursor: pointer;
  display: block;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
}

#cc-main .section__toggle:disabled {
  cursor: not-allowed;
}

#cc-main .toggle__icon {
  background: var(--cc-toggle-off-bg);
  box-shadow: 0 0 0 1px var(--cc-toggle-off-bg);
  display: flex;
  flex-direction: row;
  pointer-events: none;
  position: absolute;
  transition: all 0.25s ease;
}

#cc-main .toggle__icon-circle {
  background: var(--cc-toggle-off-knob-bg);
  border: none;
  border-radius: var(--cc-pm-toggle-border-radius);
  box-shadow: 0 1px 2px rgba(24, 32, 3, 0.36);
  display: block;
  height: 23px;
  left: 0;
  position: absolute;
  top: 0;
  transition: transform 0.25s ease, background-color 0.25s ease;
  width: 23px;
}

#cc-main .toggle__icon-off,
#cc-main .toggle__icon-on {
  height: 100%;
  position: absolute;
  transition: opacity 0.15s ease;
  width: 100%;
}

#cc-main .toggle__icon-on {
  opacity: 0;
  transform: rotate(45deg);
}

#cc-main .toggle__icon-on svg {
  stroke: var(--cc-toggle-on-bg);
  transform: scale(0.55) rotate(-45deg);
}

#cc-main .toggle__icon-off {
  opacity: 1;
}

#cc-main .toggle__icon-off svg {
  stroke: var(--cc-toggle-off-bg);
  transform: scale(0.55);
}

#cc-main .section__toggle:checked ~ .toggle__icon {
  background: var(--cc-toggle-on-bg);
  box-shadow: 0 0 0 1px var(--cc-toggle-on-bg);
}

#cc-main .section__toggle:checked ~ .toggle__icon .toggle__icon-circle {
  background-color: var(--cc-toggle-on-knob-bg);
  transform: translateX(27px);
}

#cc-main .section__toggle:checked ~ .toggle__icon .toggle__icon-on {
  opacity: 1;
}

#cc-main .section__toggle:checked ~ .toggle__icon .toggle__icon-off {
  opacity: 0;
}

#cc-main .section__toggle:checked:disabled ~ .toggle__icon {
  background: var(--cc-toggle-readonly-bg);
  box-shadow: 0 0 0 1px var(--cc-toggle-readonly-bg);
}

#cc-main .section__toggle:checked:disabled ~ .toggle__icon .toggle__icon-circle {
  background: var(--cc-toggle-readonly-knob-bg);
  box-shadow: none;
}

#cc-main .section__toggle:checked:disabled ~ .toggle__icon svg {
  stroke: var(--cc-toggle-readonly-knob-icon-color);
}

#cc-main .toggle__label {
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: -1;
}

#cc-main .pm__section-desc-wrapper {
  color: var(--cc-secondary-color);
  display: flex;
  flex-direction: column;
}

#cc-main .pm__section-desc-wrapper > :not(:last-child) {
  border-bottom: 1px solid var(--cc-cookie-category-block-border);
}

#cc-main .pm__section-services {
  display: flex;
  flex-direction: column;
}

#cc-main .pm__service {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  transition: background-color 0.15s ease;
}

#cc-main .pm__service:hover {
  background-color: var(--cc-cookie-category-block-hover-bg);
}

#cc-main .pm__service-header {
  align-items: center;
  display: flex;
  margin-right: 1em;
  width: 100%;
}

#cc-main .pm__service-icon {
  border: 2px solid;
  border-radius: 100%;
  height: 8px;
  margin-left: 6px;
  margin-right: 20px;
  margin-top: 1px;
  min-width: 8px;
}

#cc-main .pm__service-title {
  width: 100%;
  word-break: break-word;
}

#cc-main .pm__section-table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  text-align: left;
  width: 100%;
}

#cc-main .pm__table-caption {
  text-align: left;
}

#cc-main .pm__table-caption,
#cc-main .pm__table-head > tr {
  border-bottom: 1px dashed var(--cc-separator-border-color);
  color: var(--cc-primary-color);
  font-weight: 600;
}

#cc-main .pm__table-tr {
  transition: background-color 0.15s ease;
}

#cc-main .pm__table-tr:hover {
  background: var(--cc-cookie-category-block-hover-bg);
}

#cc-main .pm__table-caption,
#cc-main .pm__table-td,
#cc-main .pm__table-th {
  vertical-align: top;
}

#cc-main .pm__footer {
  border-top: 1px solid var(--cc-separator-border-color);
  display: flex;
  gap: 0.4rem;
  justify-content: space-between;
  padding: 0.8rem 2.4rem;
}

#cc-main .pm__btn-group {
  display: flex;
  gap: 0.4rem;
}

#cc-main .pm--box {
  height: calc(100% - 2em);
  margin: 0 auto;
  max-height: 37.5em;
  max-width: 43em;
  left: var(--cc-modal-margin);
  right: var(--cc-modal-margin);
  top: 50%;
  transform: translateY(calc(-50% + 1.6em));
}

#cc-main .pm-overlay {
  background: var(--cc-overlay-bg);
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 1;
}

#cc-main .cc--anim .pm,
#cc-main .cc--anim .pm-overlay {
  transition: opacity var(--cc-modal-transition-duration) ease, visibility var(--cc-modal-transition-duration) ease, transform var(--cc-modal-transition-duration) ease;
}

.show--preferences #cc-main .cc--anim .pm {
  opacity: 1;
  visibility: visible !important;
}

.show--preferences #cc-main .cc--anim .pm-overlay {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 640px) {
  #cc-main .pm {
    border-radius: 0;
    bottom: 0;
    height: auto;
    left: 0;
    max-height: 100%;
    max-width: none !important;
    right: 0;
    top: 0;
    transform: translateY(1.6rem);
  }
  #cc-main .pm__btn-group,
  #cc-main .pm__footer {
    flex-direction: column !important;
  }
  #cc-main .pm__btn-group {
    display: flex !important;
  }
  .show--preferences #cc-main .cc--anim .pm {
    transform: translateY(0) !important;
  }
}