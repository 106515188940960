$columns: 12;

.c-form__fieldset {
    padding: 0;
    margin: 0;
    border: 0;

    &__title {
        @extend h3;
    }
}

.c-form__row {
    display: grid;
    grid-template-columns: repeat($columns, 1fr);
    gap: 0 2.4rem;
}

.c-form__column {
    &--#{$columns} {
        grid-column: span $columns;
    }
}

.c-form__navigation {
    &__layout {
        display: grid;
        grid-template-columns: 1fr auto;
    }
}

.c-input__label {
    display: block;
    margin-bottom: 0.4rem;
}

.c-input {
    margin-bottom: 1.6rem;
}

.c-input__field--text,
.c-input__field--textarea,
.c-input__field--dropdown,
.c-input__field--date,
.c-input__field--file {
    display: block;
    width: 100%;
    background-color: $colorWhite;
    border: 1px solid $colorSand;
    margin: 0;
    padding: 1.2rem 1.6rem;
    border-radius: 0.4rem;
    font-family: $fontFamilyDefault;
    font-size: 1.4rem;
    color: $fontColorDefault;

    &:focus {
        outline: none;
    }
}

.c-input__field--text,
.c-input__field--dropdown,
.c-input__field--date {
    height: 4rem;
}

.c-input__field--dropdown {
    background-image: url(../img/chevron-down.svg);
    background-size: 1.6rem 1.6rem;
    background-repeat: no-repeat;
    background-position: top 1.2rem right 1.6rem;
    appearance: none;

    &:hover {
        cursor: pointer;
    }
}

.c-input__field--date {
    background-image: url(../img/calendar.svg);
    background-size: 1.6rem 1.6rem;
    background-repeat: no-repeat;
    background-position: top 1.2rem right 1.6rem;

    &:hover {
        cursor: pointer;
    }
}

.c-input__field--textarea {
    height: 20rem;
    min-width: 100%;
    max-width: 100%;
}

.c-input__field--checkbox,
.c-input__field--radio {
    margin: 0;
    padding: 0;
    accent-color: $colorSand;

    &:hover {
        cursor: pointer;
    }
}

.c-input__field__label--checkbox,
.c-input__field__label--radio {
    &:hover {
        cursor: pointer;
    }
}

.c-input__field__list--file {
    margin-top: .4rem;
}

.field-validation-error {
    display: block;
    margin-top: .4rem;
    font-size: smaller;
    color: $colorMediumGray;
    padding-left: 2rem;
    background-image: url(../img/exclamation.svg);
    background-size: 1.4rem 1.4rem;
    background-repeat: no-repeat;
    background-position: top .5rem left 0;
}

@media only screen and (min-width: breakpoint("md")) {
    .c-form__column {
        @for $i from 1 through $columns {
            &--#{$i}--md {
                grid-column: span $i;
            }
        }
    }
}
