h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-family: $fontFamilyTitle;
  font-weight: normal;
  line-height: 1.3em;
  color: inherit;

  &:last-child {
    margin-bottom: 0;
  }
}

h1,
.h1 {
  font-size: 2.8rem;
  margin-bottom: calc(1.3em / 2.4);

  @media only screen and (min-width: breakpoint("lg")) {
    font-size: 3.2rem;
    margin-bottom: calc(1.3em / 3.2);
  }
}

h2,
.h2 {
  font-size: 2rem;
  margin-bottom: calc(1.3em / 2);

  @media only screen and (min-width: breakpoint("lg")) {
    font-size: 2.8rem;
    margin-bottom: calc(1.3em / 2.4);
  }
}

h3,
.h3 {
  font-size: 1.6rem;
  margin-bottom: calc(1.3em / 1.6);

  @media only screen and (min-width: breakpoint("lg")) {
    font-size: 2rem;
    margin-bottom: calc(1.3em / 2);
  }
}

h4,
.h4 {
  font-size: 1.4rem;
  margin-bottom: calc(1.3em / 1.4);

  @media only screen and (min-width: breakpoint("lg")) {
    font-size: 1.8rem;
    margin-bottom: calc(1.3em / 1.8);
  }
}

h5,
.h5 {
  font-size: 1.4rem;
  margin-bottom: calc(1.3em / 1.4);

  @media only screen and (min-width: breakpoint("lg")) {
    font-size: 1.6rem;
    margin-bottom: calc(1.3em / 1.6);
  }
}

h6,
.h6 {
  font-size: 1.4rem;
  margin-bottom: calc(1.3em / 1.4);

  @media only screen and (min-width: breakpoint("lg")) {
    font-size: 1.4rem;
    margin-bottom: calc(1.3em / 1.4);
  }
}

p,
ol,
ul {
  margin-bottom: calc(1.3em / 1.5);

  &:last-child {
    margin-bottom: 0;
  }
}

.text-beige {
  color: $colorBeige;
}

.text-sand {
  color: $colorSand;
}

.text-oak {
  color: $colorOak;
}

.text-white {
  color: $colorWhite;
}
