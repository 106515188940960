.u-text {
  a:not([class]):not([href*="tel:"]) {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  ul {
    list-style: none;

    ol,
    ul {
      padding-left: 3.2rem;
    }

    > li {
      & + li {
        margin-top: 0.8rem;
      }

      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 1.6rem;
        height: 1.6rem;
        background-image: url("../img/checkmark.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top 0 left 0;
        margin-right: 0.6rem;
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: alpha;

    ol,
    ul {
      padding-left: 3.2rem;
    }

    > li {
      counter-increment: alpha;

      & + li {
        margin-top: 0.8rem;
      }

      &:before {
        content: counters(alpha, ".") ". ";
      }
    }
  }
}
