.c-grid__layout--blocks {
    position: relative;
    display: grid;
    grid-template-columns: repeat(var(--umb-block-grid--grid-columns, 1), minmax(0, 1fr));
    grid-auto-flow: row;
    grid-auto-rows: minmax(0, auto);
    column-gap: var(--umb-block-grid--column-gap, 0);
    row-gap: var(--umb-block-grid--row-gap, 0);
    min-height: 100%;

    .c-section & {
        row-gap: var(--umb-block-grid--areas-row-gap, 4rem);
    }
}

.c-grid__item--blocks {
    /* For small devices we scale columnSpan by three, to make everything bigger than 1/3 take full width: */
    grid-column-end: span min(calc(var(--umb-block-grid--item-column-span, 1) * 3), var(--umb-block-grid--grid-columns));
    grid-row: span var(--umb-block-grid--item-row-span, 1);
}

.c-grid__layout--area,
.c-grid__layout--blocks::part(area-container) {
    position: relative;
    display: grid;
    grid-template-columns: repeat(var(--umb-block-grid--area-grid-columns, var(--umb-block-grid--grid-columns, 1)),
            minmax(0, 1fr));
    grid-auto-flow: row;
    grid-auto-rows: minmax(0, auto);
    column-gap: var(--umb-block-grid--areas-column-gap, 0);
    row-gap: var(--umb-block-grid--areas-row-gap, 4rem);
}

.c-grid__item--area {
    position: relative;
    /* For small devices we scale columnSpan by twelve, to make everything bigger than 1/12 take full width: */
    grid-column-end: span min(calc(var(--umb-block-grid--area-column-span, 1) * 12), var(--umb-block-grid--area-grid-columns));
    grid-row: span var(--umb-block-grid--area-row-span, 1);
}

@media only screen and (min-width: breakpoint("md")) {
    .c-grid__layout--blocks {
        .c-section & {
            column-gap: var(--umb-block-grid--areas-column-gap, 4rem);
        }
    }

    .c-grid__item--blocks {
        grid-column-end: span min(var(--umb-block-grid--item-column-span, 1), var(--umb-block-grid--grid-columns));
    }

    .c-grid__layout--area {
        column-gap: var(--umb-block-grid--areas-column-gap, 4rem);
    }

    .c-grid__item--area {
        grid-column-end: span min(var(--umb-block-grid--area-column-span, 1), var(--umb-block-grid--area-grid-columns));
    }
}