.c-card {
    display: block;

    &__body {
        display: flex;
        flex-direction: column;

        > *:last-child {
            margin-top: auto;
        }
    }

    &--blog {
        display: grid;
        grid-template-rows: auto 1fr;
    }

    &--jobs {
        display: grid;
        grid-template-rows: 1fr;
    }
}
