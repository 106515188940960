.o-blog {
    &__layout {
        display: grid;
        gap: 3.2rem;
    }
}


@media only screen and (min-width: breakpoint("md")) {
    .o-blog {
        &__layout {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}