.o-footer {
    background-color: $colorBlack;
    color: $colorWhite;

    &__layout {
        display: grid;
        grid-template-columns: 1fr;
        gap: 4rem;
    }

    &__address {
        svg {
            display: inline-block;
            vertical-align: middle;
            width: 1.6rem;
            height: 1.6rem;
        }
    }
}

@media only screen and (min-width: breakpoint("md")) {
    .o-footer {
        &__layout {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
