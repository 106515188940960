.c-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding: 1rem 1.4rem;
    min-height: 4rem;
    background-color: transparent;
    border: 1px solid transparent;
    font-family: $fontFamilyTitle;
    font-size: 1.6rem;
    text-align: center;
    line-height: 1em;
    white-space: nowrap;

    &:hover {
        cursor: pointer;
    }

    &--white {
        background-color: $colorWhite;
        color: $colorBlack;

        &:hover {
            background-color: darken($colorWhite, 8%);
            color: $colorBlack;
        }
    }

    &--beige {
        background-color: $colorBeige;
        color: $colorBlack;

        &:hover {
            background-color: darken($colorBeige, 8%);
            color: $colorBlack;
        }
    }

    &--sand {
        background-color: $colorSand;
        color: $colorBlack;

        &:hover {
            background-color: darken($colorSand, 8%);
            color: $colorBlack;
        }
    }

    &--oak {
        background-color: $colorOak;
        color: $colorWhite;

        &:hover {
            background-color: darken($colorOak, 8%);
            color: $colorWhite;
        }
    }

    &--black {
        background-color: $colorBlack;
        color: $colorWhite;

        &:hover {
            background-color: darken($colorBlack, 8%);
            color: $colorWhite;
        }
    }

    &--ghost {
        background-color: transparent;
        border-color: $colorSand;

        &:hover {
            border-color: $colorOak;
        }
    }
}