html {
    box-sizing: border-box;
    font-size: 62.5%;
}

body {
    background-color: $colorWhite;
    font-family: $fontFamilyDefault;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.6em;
    color: $fontColorDefault;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
}


ol,
ul {
    list-style: none;
}

img {
    display: block;
    max-width: 100%;
    width: auto;
    height: auto;
}

a {
    text-decoration: none;
    color: inherit;

    &:hover {
        color: inherit;
    }
}

address {
    font-style: normal;
}
