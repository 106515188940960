.c-brand {
    width: 10rem;
}

@media only screen and (min-width: breakpoint("lg")) {
    .c-brand {
        display: block;
        width: 14rem;
    }
}
